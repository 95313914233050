import React from 'react';

import classNames from 'classnames';

import Icon from '@helsenorge/designsystem-react/components/Icons';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';

import { theme } from '@helsenorge/designsystem-react';

export interface SeksjonProps {
  header: string;
  content: Array<JSX.Element>;
}

const Seksjon = (props: SeksjonProps): JSX.Element | null => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

  const toggleExpanded = (): void => {
    setIsExpanded(!isExpanded);
  };
  const handleKeyDown = (keyPressed: string): void => {
    if (keyPressed === 'Enter') {
      toggleExpanded();
    } else {
      return;
    }
  };

  const sectionClasses: string = classNames('step', { open: isExpanded });
  return (
    <div className="skjemasidebar__draweraccordion">
      <section className={sectionClasses}>
        <div
          onClick={toggleExpanded}
          onKeyDown={(event): void => {
            handleKeyDown(event.key);
          }}
          role="button"
          tabIndex={0}
        >
          <span className="skjemasidebar__draweraccordionstepheader">
            <button type="button" className="skjemasidebar__draweraccordionsteptitle" aria-expanded={isExpanded}>
              {isExpanded ? (
                <Icon svgIcon={ChevronUp} color={theme.palette.blueberry700} />
              ) : (
                <Icon svgIcon={ChevronDown} color={theme.palette.blueberry700} />
              )}
              <h2>{props.header}</h2>
            </button>
          </span>
        </div>
        {isExpanded && (
          <div className={`skjemasidebar__draweraccordionstepcontents ${designsystemtypography['anchorlink-wrapper']} `}>
            {props.content}
          </div>
        )}
      </section>
    </div>
  );
};

export default Seksjon;
