import { Language } from '../types/Language';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getUserLanguage } from '@helsenorge/framework-utils/hn-language';

import { DEFAULT_LANGUAGE, LANGUAGE_Code } from '../constants/constants';

export const mapToLanguageLocales = (language: string | undefined | null): LanguageLocales | undefined => {
  switch (language?.toLowerCase()) {
    case LanguageLocales.NORWEGIAN_NYNORSK.toLowerCase():
      return LanguageLocales.NORWEGIAN_NYNORSK;
    case LanguageLocales.ENGLISH.toLowerCase():
      return LanguageLocales.ENGLISH;
    case LanguageLocales.SAMI_NORTHERN.toLowerCase():
      return LanguageLocales.SAMI_NORTHERN;
    case LanguageLocales.LITHUANIAN.toLowerCase():
      return LanguageLocales.LITHUANIAN;
    case LanguageLocales.POLISH.toLowerCase():
      return LanguageLocales.POLISH;
    case LanguageLocales.RUSSIAN.toLowerCase():
      return LanguageLocales.RUSSIAN;
    case LanguageLocales.ROMANIAN.toLowerCase():
      return LanguageLocales.ROMANIAN;
    case LanguageLocales.NORWEGIAN.toLowerCase():
      return LanguageLocales.NORWEGIAN;
    default:
      return undefined;
  }
};

export const isValidPortalLanguage = (language: string | undefined): boolean => {
  if (
    language === LanguageLocales.NORWEGIAN ||
    language === LanguageLocales.NORWEGIAN_NYNORSK ||
    language === LanguageLocales.ENGLISH ||
    language === LanguageLocales.SAMI_NORTHERN
  ) {
    return true;
  } else {
    return false;
  }
};

export const getPortalLanguage = (): LanguageLocales => {
  const userLanguage = getUserLanguage();
  const language = mapToLanguageLocales(userLanguage);
  return language && isValidPortalLanguage(language) ? language : LanguageLocales.NORWEGIAN;
};

export const isPortalLanguageInDefinition = (portalLanguage: string, languagesInDefinition: Language[]): boolean => {
  const languageExists = languagesInDefinition.filter(x => x.Language === portalLanguage);
  if (languageExists.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getUrlLanguage = (): LanguageLocales | undefined => {
  const params = new URLSearchParams(window.location.search);
  if (params.has(LANGUAGE_Code)) {
    return mapToLanguageLocales(params.get(LANGUAGE_Code));
  }
  return undefined;
};

export const getDefaultLanguage = (languages: Language[]): LanguageLocales => {
  const urlLanguage = getUrlLanguage();
  if (urlLanguage && isPortalLanguageInDefinition(urlLanguage, languages)) {
    return urlLanguage;
  }

  const portalLanguage = getPortalLanguage();
  if (isPortalLanguageInDefinition(portalLanguage, languages)) {
    const defaultLanguage = languages.filter(x => x.Language === portalLanguage);
    if (defaultLanguage.length === 1) {
      return defaultLanguage[0].Language as LanguageLocales;
    }
  }

  return DEFAULT_LANGUAGE as LanguageLocales;
};
