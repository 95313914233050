import { Questionnaire, QuestionnaireResponse, BundleEntry, Bundle } from 'fhir/r4';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { TextMessage, Metadata, GetSkjemaResponse, GetSkjemasvarResponse } from '../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../types/Resources';
import { SkjemaProxyErrorResponse } from '../types/SkjemaProxyErrorResponse';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { get } from '@helsenorge/framework-utils/hn-proxy-service';
import { HNeventSetDriftsmeldingPath } from '@helsenorge/framework-utils/web-component/events';
import { setSkjemaDefinition } from '@helsenorge/refero/actions/form';
import { generateQuestionnaireResponse } from '@helsenorge/refero/actions/generateQuestionnaireResponse';

import { redirectTilInnsendtSkjemaUrl, redirectTilLagretSkjemaPaaDokumenterUrl } from '../constants/apiConsts';
import { DEFAULT_LANGUAGE, SKJEMA_URL } from '../constants/constants';
import LocalStorageConstants from '../constants/localstorage';
import { HnGlobalState } from '../reducers/index';
import { getResources } from '../resources';
import { getSkjemaNameFromUrl, getQueryParams, toRelativeUrl, fjernVedleggFraDokumentHvisGjenbrukes } from '../util';
import { getLanguagesInDefinition } from '../util/getLanguagesInDefinition';
import { getDefaultLanguage, isValidPortalLanguage } from '../util/portalLanguage-utils';
import { stringToLanguageLocales } from '../util/stringToLanguageLocales';
import { trackFormEvent } from '../util/trackFormEvent';

export interface SkjemaAction extends Action {
  questionnaireResponse?: QuestionnaireResponse;
  dokumentGuid?: string;
  samtaleGuid?: string;
  questionnaire?: Questionnaire;
  language?: string | null;
  newFormDefinitionUrl?: string | undefined;
  error?: string;
  warning?: TextMessage;
  userMetadata?: Metadata;
  blockSubmit?: boolean;
  show?: boolean;
  version?: string;
  resources?: HNSkjemaFrontend;
  questionnaires?: Array<Questionnaire>;
  taskId?: string;
  returUrl?: string;
}

export type REQUEST_SKJEMAOOPPGAVE = 'skjemautfyller/REQUEST_SKJEMAOOPPGAVE';
export const REQUEST_SKJEMAOOPPGAVE: REQUEST_SKJEMAOOPPGAVE = 'skjemautfyller/REQUEST_SKJEMAOOPPGAVE';

export type RECEIVE_SKJEMAOPPGAVE = 'skjemautfyller/RECEIVE_SKJEMAOPPGAVE';
export const RECEIVE_SKJEMAOPPGAVE: RECEIVE_SKJEMAOPPGAVE = 'skjemautfyller/RECEIVE_SKJEMAOPPGAVE';

export type RECEIVE_SKJEMA_DEFINITION = 'skjemautfyller/RECEIVE_SKJEMA_DEFINITION';
export const RECEIVE_SKJEMA_DEFINITION: RECEIVE_SKJEMA_DEFINITION = 'skjemautfyller/RECEIVE_SKJEMA_DEFINITION';

export type SHOW_LOGIN_MESSAGE = 'skjemautfyller/SHOW_LOGIN_MESSAGE';
export const SHOW_LOGIN_MESSAGE: SHOW_LOGIN_MESSAGE = 'skjemautfyller/SHOW_LOGIN_MESSAGE';

export type FAILURE_SKJEMAOOPPGAVE = 'skjemautfyller/FAILURE_SKJEMAOOPPGAVE';
export const FAILURE_SKJEMAOOPPGAVE: FAILURE_SKJEMAOOPPGAVE = 'skjemautfyller/FAILURE_SKJEMAOOPPGAVE';

export type SHOULD_SHOW_BEFORE_UNLOAD = 'skjemautfyller/SHOULD_SHOW_BEFORE_UNLOAD';
export const SHOULD_SHOW_BEFORE_UNLOAD: SHOULD_SHOW_BEFORE_UNLOAD = 'skjemautfyller/SHOULD_SHOW_BEFORE_UNLOAD';

export type DISMISS_ANONYMOUS_MESSAGE = 'skjemautfyller/DISMISS_ANONYMOUS_MESSAGE';
export const DISMISS_ANONYMOUS_MESSAGE: DISMISS_ANONYMOUS_MESSAGE = 'skjemautfyller/DISMISS_ANONYMOUS_MESSAGE';

export const BLOCK_SUBMIT = 'skjemautfyller/BLOCK_SUBMIT';

export type CLOSE_CONTINUE_OR_LEAVE_MESSAGE = 'skjemautfyller/CLOSE_CONTINUE_OR_LEAVE_MESSAGE';
export const CLOSE_CONTINUE_OR_LEAVE_MESSAGE: CLOSE_CONTINUE_OR_LEAVE_MESSAGE = 'skjemautfyller/CLOSE_CONTINUE_OR_LEAVE_MESSAGE';

export type CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE = 'skjemautfyller/CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE';
export const CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE: CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE =
  'skjemautfyller/CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE';

export const SET_RESOURCES = 'skjemautfyller/SET_RESOURCES';
export const SKJEMA_LOADED = 'skjemautfyller/SKJEMA_LOADED';
export const SHOW_ATTACHEMENT_MAX_FILES_VISIBLE = 'skjemautfyller/SHOW_ATTACHEMENT_MAX_FILES_VISIBLE';
export const HIDE_ATTACHEMENT_MAX_FILES_VISIBLE = 'skjemautfyller/HIDE_ATTACHEMENT_MAX_FILES_VISIBLE';

function requestSkjemaoppgave(): SkjemaAction {
  return {
    type: REQUEST_SKJEMAOOPPGAVE,
  };
}

export function dismissAnonymousMessage(): SkjemaAction {
  return {
    type: DISMISS_ANONYMOUS_MESSAGE,
  };
}

export function shouldShowBeforeUnload(show: boolean): SkjemaAction {
  return {
    type: SHOULD_SHOW_BEFORE_UNLOAD,
    show,
  };
}

export function blockSubmit(blockSubmit: boolean): SkjemaAction {
  return {
    type: BLOCK_SUBMIT,
    blockSubmit,
  };
}

export function closeContinueOrLeaveMessage(): SkjemaAction {
  return {
    type: CLOSE_CONTINUE_OR_LEAVE_MESSAGE,
  };
}

export function closeContinueOrLeaveMessageAndLeave(): SkjemaAction {
  return {
    type: CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE,
  };
}

export function showAttachmentMaxFilesError(): SkjemaAction {
  return {
    type: SHOW_ATTACHEMENT_MAX_FILES_VISIBLE,
  };
}

export function hideAttachmentMaxFilesError(): SkjemaAction {
  return {
    type: HIDE_ATTACHEMENT_MAX_FILES_VISIBLE,
  };
}

export function skjemaDefinisjonLoaded(
  questionnaires: Array<Questionnaire>,
  userMetadata: Metadata,
  warning: TextMessage | undefined,
  dokumentGuid: string | undefined,
  taskId: string | undefined,
  returUrl: string | undefined
): SkjemaAction {
  return {
    type: SKJEMA_LOADED,
    questionnaires,
    userMetadata,
    warning,
    dokumentGuid,
    taskId,
    returUrl,
  };
}

export function setResources(resources: HNSkjemaFrontend): SkjemaAction {
  return {
    type: SET_RESOURCES,
    resources,
  };
}

export const gotoUrl = (url: string): void => {
  window.location.assign(url);
};

export const redirectTilLagretSkjemaPaaDokumenter = (id?: string): void => {
  gotoUrl(redirectTilLagretSkjemaPaaDokumenterUrl(id));
};

export const redirectTilInnsendtSkjema = (id: string): void => {
  gotoUrl(redirectTilInnsendtSkjemaUrl(id));
};

export const gotoMinHelse = (): void => {
  gotoUrl('/');
};

export const postUrl = (url: string, params: { contextParameter?: string | undefined; dokumentGuid?: string | undefined }): void => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;

  for (const key in params) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = params[key];

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
};

export function promptLoginMessage(): SkjemaAction {
  return {
    type: SHOW_LOGIN_MESSAGE,
  };
}

export function failureSkjemaoppgave(errorMessage: string): SkjemaAction {
  return {
    type: FAILURE_SKJEMAOOPPGAVE,
    error: errorMessage,
  };
}

export function abortSkjema() {
  return (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>, getState: () => HnGlobalState): void => {
    dispatch(shouldShowBeforeUnload(false));
    const returUrl = decodeURIComponent(getState().refero.ui.returUrl || '');
    if (returUrl) {
      const returnUrlRelative = toRelativeUrl(returUrl);
      gotoUrl(returnUrlRelative);
    } else if (getState().refero.ui.dokumentGuid) {
      redirectTilLagretSkjemaPaaDokumenter();
    } else {
      gotoMinHelse();
    }
  };
}

export function redirectToReturUrlHvisEksisterer() {
  return (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>, getState: () => HnGlobalState): void => {
    dispatch(shouldShowBeforeUnload(false));
    const returUrl = decodeURIComponent(getState().refero.ui.returUrl || '');
    if (returUrl) {
      const returnUrlRelative = toRelativeUrl(returUrl);
      gotoUrl(returnUrlRelative);
    } else {
      redirectTilLagretSkjemaPaaDokumenter();
    }
  };
}

export function loadResources(language: string) {
  return (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>): void => {
    getResources('HN.Skjema.Frontend', stringToLanguageLocales(language))
      .then((resources: HNSkjemaFrontend) => {
        dispatch(setResources(resources));
      })
      .catch((error: TextMessage | undefined) => {
        throw error;
      });
  };
}

function getQuestionnaireInLanguage(questionnaires: Array<Questionnaire>, language: string): Questionnaire | undefined {
  const skjemaDefinition = questionnaires.filter(q => q.language?.toLowerCase() === language?.toLowerCase());
  return skjemaDefinition.length > 0 ? skjemaDefinition[0] : undefined;
}

function getLoadParameter(query: string, skjemaTekniskNavnFraUrl: string): [string, string] {
  if (skjemaTekniskNavnFraUrl) {
    return ['name', skjemaTekniskNavnFraUrl];
  }
  const nameMatchString = 'Questionnaire?name=';
  const idMatchString = 'Questionnaire/';
  const hasNameParam = decodeURIComponent(query).indexOf(nameMatchString) > -1;
  return hasNameParam
    ? ['name', decodeURIComponent(query).substr(nameMatchString.length)]
    : ['id', decodeURIComponent(query).substr(idMatchString.length)];
}

function getFilteredParams(): string {
  const params = { ...getQueryParams() };
  delete params.Query;

  const stringParams = Object.keys(params).map(key => {
    return `${key}=${params[key]}`;
  });

  return stringParams.join('&');
}

function getNewUrl(tekniskNavn: string): string {
  return `${SKJEMA_URL}/${tekniskNavn}`;
}

const onError = (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>, error: SkjemaProxyErrorResponse): void => {
  if (error.Code === 'SKJEMA-000252') {
    dispatch(failureSkjemaoppgave('oppgavenFinsIkke'));
  } else {
    dispatch(failureSkjemaoppgave(error.Message || 'FEIL'));
  }
};

const onReceive = (
  dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>,
  data: string,
  metadata: Metadata,
  warning: TextMessage | undefined,
  questionnaireResponse?: string,
  isMicroweb?: boolean,
  formparent?: string
): void => {
  // parse data, check for bundle
  const parsedData: Bundle | Questionnaire = JSON.parse(data);
  const questionnaires: Array<Questionnaire> = [];
  if (parsedData.resourceType === 'Bundle') {
    // dersom Bundle er tom
    if (parsedData.total === 0) {
      dispatch(failureSkjemaoppgave('oppgavenFinsIkke'));
      return;
    }
    parsedData.entry?.forEach((entry: BundleEntry) => {
      const resource = entry.resource as Questionnaire;
      questionnaires.push(resource);
    });
  } else {
    questionnaires.push(parsedData);
  }

  const languages = getLanguagesInDefinition(questionnaires);
  const preferredLanguage = getDefaultLanguage(languages);
  const questionnaireInLanguage = getQuestionnaireInLanguage(questionnaires, preferredLanguage);

  if (questionnaireInLanguage && preferredLanguage.toLowerCase() !== DEFAULT_LANGUAGE.toLowerCase()) {
    // last ressurser for foretrukket språk
    dispatch(loadResources(preferredLanguage.toLowerCase()));
  } else if (
    questionnaires.length === 1 &&
    questionnaires[0].language &&
    questionnaires[0].language.toLowerCase() !== DEFAULT_LANGUAGE.toLowerCase()
  ) {
    // dersom vi bare har en questionnaire, og denne er på et annet språk enn default, prøv å laste ressurser for dette språket
    dispatch(loadResources(questionnaires[0].language.toLowerCase()));
  }
  const mainQuestionnaire = questionnaireInLanguage || questionnaires[0];

  // endre url og hent driftsmelding for dette skjema
  if (!isMicroweb) {
    const filteredParams = getFilteredParams();
    const filteredParamsUrl = filteredParams ? `?${filteredParams}` : '';
    const newUrlPath = getNewUrl(mainQuestionnaire.name || '').replace(/\/+/g, '/');
    window.history.replaceState(window.history.state, '', `/${newUrlPath}${filteredParamsUrl}`);
    HNeventSetDriftsmeldingPath(encodeURIComponent(newUrlPath));
  }
  // dispatch new state
  const queryParams = getQueryParams();
  dispatch(skjemaDefinisjonLoaded(questionnaires, metadata, warning, queryParams.contextId, queryParams.taskId, queryParams.returURL));

  let qr: QuestionnaireResponse = questionnaireResponse
    ? JSON.parse(questionnaireResponse)
    : generateQuestionnaireResponse(mainQuestionnaire);

  if (isMicroweb) {
    const qrMicroweb: QuestionnaireResponse | undefined =
      questionnaireResponse && formparent !== undefined ? JSON.parse(formparent) : undefined;
    qr = qrMicroweb as QuestionnaireResponse;
  }

  fjernVedleggFraDokumentHvisGjenbrukes(mainQuestionnaire, qr, metadata);
  dispatch(setSkjemaDefinition(mainQuestionnaire, qr));
};

export function fetchSkjemaoppgave(skjemaTekniskNavn?: string, documentGuid?: string, isMicroweb?: boolean, formparent?: string) {
  return (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>): void => {
    dispatch(requestSkjemaoppgave());
    const skjemaNavnParam = (isMicroweb && skjemaTekniskNavn) || getSkjemaNameFromUrl();

    const params = getQueryParams();
    const documentGuidParam = (isMicroweb && documentGuid) || params.contextId;

    if (documentGuidParam && isAuthorized()) {
      // hent mellomlagret skjema (send med OppgaveGuid dersom taskId er med som url-parameter)
      const getParams = {
        ...(params.taskId && { OppgaveGuid: params.taskId }),
        DokumentGuid: documentGuidParam,
      };
      get<GetSkjemasvarResponse>('skjemainternal', 'api/v1/Skjemasvar', getParams)
        .then((data: GetSkjemasvarResponse) => {
          trackFormEvent('form continue', skjemaNavnParam);
          onReceive(dispatch, data.Questionnaire, data.Metadata, data.WarningMessage, data.QuestionnaireResponse, isMicroweb);
        })
        .catch((error: SkjemaProxyErrorResponse) => onError(dispatch, error));
    } else if (params.taskId && isAuthorized()) {
      // hent skjema fra oppgave. Ikke mellomlagret. ResourceId er optional (dersom skjemaoppgave har vedlagt skjemadefinisjon)
      const getParams = {
        ...(params.resourceId && { Id: params.resourceId }),
        OppgaveGuid: params.taskId,
      };
      get<GetSkjemaResponse>('skjemainternal', 'api/v1/Skjema', getParams)
        .then((data: GetSkjemaResponse) => {
          onReceive(dispatch, data.Questionnaire, data.Metadata, data.WarningMessage, undefined, isMicroweb);
        })
        .catch((error: SkjemaProxyErrorResponse) => onError(dispatch, error));
    } else if (params.Query || skjemaNavnParam) {
      // hent skjema anonym og innlogget. Kan hente fra enten Query name, Query id eller teknisk navn fra url /<teknisk-navn>
      const [loadParamName, loadParamValue] = getLoadParameter(params.Query || '', skjemaNavnParam);
      const controllerName = isAuthorized() ? 'api/v1/Skjema' : 'api/v1/SkjemaAnonym';
      get<GetSkjemaResponse>('skjemainternal', controllerName, { [loadParamName]: loadParamValue })
        .then((data: GetSkjemaResponse) => {
          onReceive(dispatch, data.Questionnaire, data.Metadata, data.WarningMessage, undefined, isMicroweb, formparent);
        })
        .catch((error: SkjemaProxyErrorResponse) => onError(dispatch, error));
    } else {
      dispatch(failureSkjemaoppgave(''));
    }
  };
}

export function userChangeLanguage(language: string) {
  return (dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>, getState: () => HnGlobalState): void => {
    if (isValidPortalLanguage(language)) {
      window.localStorage.setItem(LocalStorageConstants.Language, language);
    }
    const state = getState().refero;
    const skjemaDefinition = getQuestionnaireInLanguage(state.ui.questionnaires, language);
    if (skjemaDefinition) {
      dispatch(loadResources(language.toLowerCase()));
      dispatch(setSkjemaDefinition(skjemaDefinition));
    }
  };
}
