export const GUIDANCE_PARAMETER = 'http://helsenorge.no/fhir/StructureDefinition/sdf-guidanceparameter';
export const GUIDANCE_ACTION = 'http://helsenorge.no/fhir/StructureDefinition/sdf-guidanceaction';
export const SAVE_CAPABILITIES = 'http://helsenorge.no/fhir/StructureDefinition/sdf-save-capabilities';
export const ACCESSIBILITY_TO_RESPONSE = 'http://ehelse.no/fhir/StructureDefinition/sdf-accessibilitytoresponse';
export const AUTHENTICATION_REQUIREMENT_URL = 'http://ehelse.no/fhir/StructureDefinition/sdf-authenticationrequirement';
export const GENERATE_PDF = 'http://ehelse.no/fhir/StructureDefinition/sdf-generatepdf';
export const RECIPIENT_URL = 'http://ehelse.no/fhir/StructureDefinition/sdf-endpoint';
export const PRINT_VERSION = 'http://helsenorge.no/fhir/StructureDefinition/sdf-questionnaire-print-version';
export const SIDEBAR = 'http://helsenorge.no/fhir/StructureDefinition/sdf-sidebar';
export const INFORMATION_MESSAGE = 'http://helsenorge.no/fhir/StructureDefinition/sdf-information-message';
