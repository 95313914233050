import { Questionnaire, QuestionnaireResponse } from 'fhir/r4';

import { TextMessage, Metadata } from '../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../types/Resources';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { SET_SKJEMA_DEFINITION } from '@helsenorge/refero/actions/form';
import { SidebarItem } from '@helsenorge/refero/types/sidebar';
import { getNavigatorExtension, getSidebarSections } from '@helsenorge/refero/util/extension';

import {
  SEND_INN_SKJEMA,
  SEND_INN_SKJEMA_DONE,
  SEND_INN_SKJEMA_FAILED,
  DISMISS_SEND_INN_SKJEMA_FAILED,
  DISMISS_LAGRE_SKJEMA_FAILED,
  DISMISS_KAN_IKKE_LAGRE_ERROR,
  LAGRE_SKJEMA_FAILED,
  LAGRE_SKJEMA,
  ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED,
  HIDE_ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED,
  LAGRE_SKJEMA_DONE,
  KAN_IKKE_LAGRE_ERROR,
} from '../actions/formData';
import {
  SkjemaAction,
  REQUEST_SKJEMAOOPPGAVE,
  SHOW_LOGIN_MESSAGE,
  FAILURE_SKJEMAOOPPGAVE,
  BLOCK_SUBMIT,
  SHOULD_SHOW_BEFORE_UNLOAD,
  DISMISS_ANONYMOUS_MESSAGE,
  CLOSE_CONTINUE_OR_LEAVE_MESSAGE,
  CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE,
  SET_RESOURCES,
  SKJEMA_LOADED,
  SHOW_ATTACHEMENT_MAX_FILES_VISIBLE,
  HIDE_ATTACHEMENT_MAX_FILES_VISIBLE,
} from '../actions/index';
import { AuthenticationRequirement } from '../constants/authenticationRequirement';
import { GUIDANCE_ACTION } from '../constants/extensions';
import { SaveCapabilites } from '../constants/saveCapabilites';
import { SidebarVisibility } from '../constants/sidebarVisibility';
import { TopMessageVisibility } from '../constants/topMessageVisibility';
import {
  getAuthenticationRequirementValue,
  getPrintVersion,
  getSaveCapabilitiesValue,
  getSidebarVisibilityValue,
  getTopMessageVisibilityValue,
} from '../util/extension';
import { hasEndpointOrTqqcComponent, hasExtension, hasEndpoint, getSkjemaNameFromUrl } from '../util/index';
import { trackFormEvent } from '../util/trackFormEvent';

export interface UiState {
  resources?: HNSkjemaFrontend;
  loadSkjemaoppgaveErrorMessage?: string;
  showGlobalSpinner?: boolean;
  showLocalSpinner?: boolean;

  // innsending/ferdigstilling
  sendInnSkjemaError?: string;
  skjemaSendtInn?: boolean;
  skjemaSendesInn?: boolean;
  samtaleGuid?: string;
  isAttachmentMaxFilesErrorVisible?: boolean;

  // mellomlagring
  lagreSkjemaError?: string;
  showContinueOrLeaveMessage?: boolean;
  dokumentGuid?: string;
  lastSavedData?: QuestionnaireResponse;
  kanIkkeLagre?: boolean;

  blockSubmit?: boolean;
  shouldShowBeforeUnload?: boolean;
  showAnonymousUploadDenied?: boolean;
  showAnonymousSkjemaLightbox?: boolean;
  notUsedForm?: boolean;

  // loading bundle:
  questionnaires: Array<Questionnaire>;
  userMetadata?: Metadata;
  loadSkjemaoppgaveWarningMessage?: TextMessage;
  taskId?: string;
  returUrl?: string;

  // derived from current selected questionnaire
  isSavedInDokumenter?: boolean;
  isNavigatorEnabled?: boolean;
  kanMellomlagres: boolean;
  kanLagresEtterInnsending: boolean;
  printReference?: string;
  sidebarData?: Array<SidebarItem>;
  sidebarVisibility?: number;
  topMessageVisibility?: number;
  harGuidanceAction?: boolean;
  harEndepunkt?: boolean;
}

export const initialState: UiState = {
  resources: undefined,
  showGlobalSpinner: false,
  showLocalSpinner: false,
  sendInnSkjemaError: undefined,
  skjemaSendtInn: false,
  skjemaSendesInn: false,
  lagreSkjemaError: undefined,
  kanIkkeLagre: false,
  blockSubmit: undefined,
  shouldShowBeforeUnload: true,
  isSavedInDokumenter: false,
  showAnonymousUploadDenied: false,
  showAnonymousSkjemaLightbox: false,
  notUsedForm: true,
  showContinueOrLeaveMessage: false,
  dokumentGuid: undefined,
  kanMellomlagres: true,
  kanLagresEtterInnsending: true,
  sidebarData: undefined,
  questionnaires: [],
  userMetadata: undefined,
  loadSkjemaoppgaveWarningMessage: undefined,
};

function getPrintReference(skjemaDefinition: Questionnaire | undefined): string | undefined {
  return skjemaDefinition ? getPrintVersion(skjemaDefinition) : '';
}

function getIsNavigatorEnabled(skjemaDefinition: Questionnaire | undefined): boolean {
  return skjemaDefinition ? !!getNavigatorExtension(skjemaDefinition) : false;
}

function getIsSavedInDokumenter(skjemaDefinition: Questionnaire | undefined): boolean {
  return skjemaDefinition ? !hasEndpointOrTqqcComponent(skjemaDefinition) : false;
}

function getSaveCapabilities(skjemaDefinition: Questionnaire | undefined): SaveCapabilites | undefined {
  return skjemaDefinition ? getSaveCapabilitiesValue(skjemaDefinition) : undefined;
}

function getShouldShowBeforeUnload(original: boolean | undefined, skjemaDefinition: Questionnaire | undefined): boolean | undefined {
  if (!skjemaDefinition) {
    return original;
  }

  const authenticationRequirement = getAuthenticationRequirementValue(skjemaDefinition);
  switch (authenticationRequirement) {
    case AuthenticationRequirement.Anonymous:
    case AuthenticationRequirement.Optional:
      return true;
    default:
      return isAuthorized() ? true : false;
  }
}

function getSidebarVisibility(skjemaDefinition: Questionnaire | undefined): number {
  if (!skjemaDefinition) return SidebarVisibility.On;
  return getSidebarVisibilityValue(skjemaDefinition);
}

function getTopMessageVisibility(skjemaDefinition: Questionnaire | undefined): number {
  if (!skjemaDefinition) return TopMessageVisibility.On;
  return getTopMessageVisibilityValue(skjemaDefinition);
}

export default function reducer(state: UiState = initialState, action: SkjemaAction): UiState {
  const saveCapabilites = getSaveCapabilities(action.questionnaire);
  switch (action.type) {
    case REQUEST_SKJEMAOOPPGAVE:
      return {
        ...state,
        showLocalSpinner: true,
      };
    case SET_SKJEMA_DEFINITION:
      return {
        ...state,
        shouldShowBeforeUnload: getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.questionnaire),
        kanMellomlagres: saveCapabilites !== SaveCapabilites.FinalVersionOnly && saveCapabilites !== SaveCapabilites.NoSaving,
        kanLagresEtterInnsending: saveCapabilites !== SaveCapabilites.NoSaving,
        isSavedInDokumenter: getIsSavedInDokumenter(action.questionnaire),
        printReference: getPrintReference(action.questionnaire),
        isNavigatorEnabled: getIsNavigatorEnabled(action.questionnaire),
        sidebarData: action.questionnaire ? getSidebarSections(action.questionnaire) : undefined,
        sidebarVisibility: getSidebarVisibility(action.questionnaire),
        topMessageVisibility: getTopMessageVisibility(action.questionnaire),
        harGuidanceAction: hasExtension(action.questionnaire, GUIDANCE_ACTION),
        harEndepunkt: hasEndpoint(action.questionnaire),
        lastSavedData: action.questionnaireResponse ? JSON.parse(JSON.stringify(action.questionnaireResponse)) : state.lastSavedData,
      };
    case SKJEMA_LOADED:
      trackFormEvent('form start', getSkjemaNameFromUrl());
      return {
        ...state,
        dokumentGuid: action.dokumentGuid,
        taskId: action.taskId,
        returUrl: action.returUrl,
        showLocalSpinner: false,
        showAnonymousSkjemaLightbox: false,
        questionnaires: action.questionnaires || [],
        userMetadata: action.userMetadata,
        loadSkjemaoppgaveWarningMessage: action.warning,
      };
    case SHOW_LOGIN_MESSAGE:
      if (state.notUsedForm) {
        return {
          ...state,
          shouldShowBeforeUnload: getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.questionnaire),
          showAnonymousSkjemaLightbox: !getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.questionnaire),
          notUsedForm: false,
        };
      }
      return state;
    case DISMISS_ANONYMOUS_MESSAGE:
      return {
        ...state,
        showAnonymousSkjemaLightbox: false,
      };
    case FAILURE_SKJEMAOOPPGAVE:
      return {
        ...state,
        loadSkjemaoppgaveErrorMessage: action.error,
        loadSkjemaoppgaveWarningMessage: action.warning ? action.warning : undefined,
        showLocalSpinner: false,
      };
    case SEND_INN_SKJEMA:
      return {
        ...state,
        showGlobalSpinner: true,
        sendInnSkjemaError: undefined,
        skjemaSendtInn: false,
        skjemaSendesInn: true,
      };
    case SEND_INN_SKJEMA_DONE:
      trackFormEvent('form complete', getSkjemaNameFromUrl());
      return {
        ...state,
        showGlobalSpinner: false,
        samtaleGuid: action.samtaleGuid,
        skjemaSendtInn: true,
        skjemaSendesInn: false,
      };
    case SEND_INN_SKJEMA_FAILED:
      return {
        ...state,
        showGlobalSpinner: false,
        sendInnSkjemaError: action.error,
        skjemaSendesInn: false,
      };
    case DISMISS_SEND_INN_SKJEMA_FAILED:
      return {
        ...state,
        sendInnSkjemaError: undefined,
      };
    case LAGRE_SKJEMA:
      return {
        ...state,
        showGlobalSpinner: true,
        lagreSkjemaError: undefined,
      };
    case LAGRE_SKJEMA_FAILED:
      return {
        ...state,
        showGlobalSpinner: false,
        lagreSkjemaError: action.error,
      };
    case LAGRE_SKJEMA_DONE:
      trackFormEvent('form save', getSkjemaNameFromUrl());
      return {
        ...state,
        showGlobalSpinner: false,
        lagreSkjemaError: undefined,
        showContinueOrLeaveMessage: true,
        dokumentGuid: action.dokumentGuid,
      };
    case DISMISS_LAGRE_SKJEMA_FAILED:
      return {
        ...state,
        lagreSkjemaError: undefined,
      };
    case KAN_IKKE_LAGRE_ERROR:
      return { ...state, kanIkkeLagre: true };
    case DISMISS_KAN_IKKE_LAGRE_ERROR:
      return { ...state, kanIkkeLagre: false };
    case BLOCK_SUBMIT:
      return { ...state, blockSubmit: action.blockSubmit };
    case SHOULD_SHOW_BEFORE_UNLOAD:
      return { ...state, shouldShowBeforeUnload: action.show };
    case ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED:
      return { ...state, showAnonymousUploadDenied: true };
    case HIDE_ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED:
      return { ...state, showAnonymousUploadDenied: false };
    case CLOSE_CONTINUE_OR_LEAVE_MESSAGE:
      return { ...state, showContinueOrLeaveMessage: false };
    case CLOSE_CONTINUE_OR_LEAVE_MESSAGE_AND_LEAVE:
      return {
        ...state,
        showContinueOrLeaveMessage: false,
        showGlobalSpinner: true,
      };
    case SET_RESOURCES:
      return {
        ...state,
        resources: action.resources,
      };
    case SHOW_ATTACHEMENT_MAX_FILES_VISIBLE:
      return {
        ...state,
        isAttachmentMaxFilesErrorVisible: true,
      };
    case HIDE_ATTACHEMENT_MAX_FILES_VISIBLE:
      return {
        ...state,
        isAttachmentMaxFilesErrorVisible: false,
      };
    default:
      return state;
  }
}
