import * as React from 'react';

import * as History from 'history';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';

import PageRoot from '@helsenorge/core-framework/pageroot';

import '../css/skjemautfyller.scss';
import SkjemaContainer from '../components';
import { SKJEMA_URL } from '../constants/constants';
import { getSkjemaNameFromUrl } from '../util';
import { getUrlLanguage } from '../util/portalLanguage-utils';

interface RootProps {
  store: Store;
}

export default class Root extends React.Component<RootProps> {
  constructor(props: RootProps) {
    super(props);
  }

  render(): JSX.Element {
    const { store }: RootProps = this.props;

    const tekniskNavn = getSkjemaNameFromUrl();
    const location = {
      ...History.createBrowserHistory().location,
      pathname: tekniskNavn ? `/${SKJEMA_URL}/${tekniskNavn}${window.location.search}` : `/${SKJEMA_URL}${window.location.search}`,
    };

    const content = (
      <Provider store={store}>
        <BrowserRouter>
          <PageRoot hasPageHeader={false} className="page_refero" fromLocation={location} language={getUrlLanguage()}>
            <SkjemaContainer />
          </PageRoot>
        </BrowserRouter>
      </Provider>
    );
    return content;
  }
}
