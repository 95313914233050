import { QuestionnaireItem, QuestionnaireResponseItem, QuestionnaireItemEnableWhen, QuestionnaireResponseItemAnswer } from 'fhir/r4';

import { FormDefinition, FormData } from '@helsenorge/refero/reducers/form';
import { QuestionnaireItemEnableBehaviorCodes } from '@helsenorge/refero/types/fhirEnums';
import { getExtension } from '@helsenorge/refero/util/extension';
import {
  Path,
  enableWhenMatchesAnswer,
  getQuestionnaireResponseItemWithLinkid,
  getAnswerFromResponseItem,
} from '@helsenorge/refero/util/refero-core';

import { gotoUrl, postUrl } from '../actions/index';
import { GUIDANCE_ACTION, GUIDANCE_PARAMETER } from '../constants/extensions';

import { toRelativeUrl } from '.';

interface ItemPair {
  questionnaireItem: QuestionnaireItem;
  questionnaireResponseItem: QuestionnaireResponseItem;
  ordering: number;
}

function getResponseItemWithLinkIdPossiblyContainingRepeat(
  linkId: string,
  items: Array<QuestionnaireResponseItem>,
  path: Array<Path> | undefined
): QuestionnaireResponseItem | undefined {
  const findResponseItem = (linkId: string, items: Array<QuestionnaireResponseItem>): QuestionnaireResponseItem | undefined => {
    for (const item of items) {
      const result = getQuestionnaireResponseItemWithLinkid(linkId, item, path || []);
      if (result) {
        return result;
      }
    }
  };

  return findResponseItem(linkId, items);
}

function isItemEnabled(qItem: QuestionnaireItem, path: Path[], qrItems: QuestionnaireResponseItem[]): boolean {
  if (!qItem.enableWhen || qItem.enableWhen.length === 0) {
    return true;
  }

  const enableMatches: Array<boolean> = [];
  qItem.enableWhen.forEach((enableWhen: QuestionnaireItemEnableWhen) => {
    const responseItem = getResponseItemWithLinkIdPossiblyContainingRepeat(enableWhen.question, qrItems, path);
    if (responseItem) {
      const matchesAnswer = enableWhenMatchesAnswer(enableWhen, responseItem.answer);
      enableMatches.push(matchesAnswer);
    }
  });

  return qItem.enableBehavior == QuestionnaireItemEnableBehaviorCodes.ALL
    ? enableMatches.every(x => x === true)
    : enableMatches.some(x => x === true);
}

export function getActiveItemsWithExtension(
  extensionUrl: string,
  qItems: QuestionnaireItem[],
  qrItems: QuestionnaireResponseItem[],
  path: Path[] = [],
  collector: ItemPair[] = [],
  ordering = [1]
): ItemPair[] {
  for (const qItem of qItems) {
    path.push({ linkId: qItem.linkId, index: 0 });
    if (isItemEnabled(qItem, path, qrItems)) {
      const extension = getExtension(extensionUrl, qItem);
      if (extension) {
        const responseItem = getResponseItemWithLinkIdPossiblyContainingRepeat(qItem.linkId, qrItems, path);
        if (responseItem) {
          collector.push({ questionnaireResponseItem: responseItem, questionnaireItem: qItem, ordering: ordering[0] });
          ordering[0] += 1;
        }
      }

      if (qItem.item) {
        getActiveItemsWithExtension(extensionUrl, qItem.item, qrItems, path, collector, ordering);
      }
    }
    path.pop();
  }
  return collector;
}

function getSingleAnswer(answer: QuestionnaireResponseItemAnswer): string | undefined {
  if (!answer) {
    return;
  }
  if (answer.valueBoolean === true || answer.valueBoolean === false) {
    return answer.valueBoolean ? 'true' : 'false';
  }

  const coding = answer.valueCoding;
  const codingValue = coding && coding.code ? String(coding.code) : null;
  const displayValue = coding && coding.display ? coding.display : null;
  if (displayValue !== null && displayValue != undefined && displayValue !== '') {
    return displayValue;
  } else if (codingValue !== null && codingValue !== undefined && codingValue !== '') {
    return codingValue;
  }

  if (answer.valueQuantity && (answer.valueQuantity.value || answer.valueQuantity.value === 0)) {
    return [answer.valueQuantity.value, answer.valueQuantity.unit].join('|');
  }
  if (answer.valueDate) {
    return answer.valueDate;
  }
  if (answer.valueDateTime) {
    return answer.valueDateTime;
  }
  if (answer.valueDecimal) {
    return answer.valueDecimal.toString();
  }
  if (answer.valueInteger || answer.valueInteger === 0) {
    return answer.valueInteger.toString();
  }
  if (answer.valueString) {
    return answer.valueString;
  }
  if (answer.valueTime) {
    return answer.valueTime;
  }
  return;
}

function getMultiAnswer(answers: QuestionnaireResponseItemAnswer[]): string {
  const values = answers.map(answer => getSingleAnswer(answer));
  return values.join('|');
}

function getAnswer(answer: QuestionnaireResponseItemAnswer | QuestionnaireResponseItemAnswer[]): string | undefined {
  if (!answer) {
    return;
  }
  return Array.isArray(answer) ? getMultiAnswer(answer) : getSingleAnswer(answer);
}

function getParamName(qItem: QuestionnaireItem): string | undefined {
  const extension = getExtension(GUIDANCE_PARAMETER, qItem);
  return extension?.valueString;
}

function collectPostParameters(qItems: QuestionnaireItem[], qrItems: QuestionnaireResponseItem[]): Record<string, string | undefined> {
  const postItems = getActiveItemsWithExtension(GUIDANCE_PARAMETER, qItems, qrItems);

  const params = {};
  if (postItems && postItems.length > 0) {
    for (const postItem of postItems) {
      const paramName = getParamName(postItem.questionnaireItem);
      const answer = getAnswerFromResponseItem(postItem.questionnaireResponseItem);
      if (paramName && answer) {
        const value = getAnswer(answer);
        params[paramName + '___Answer'] = value;
        params[paramName + '___Text'] = postItem.questionnaireItem.text;
        params[paramName + '___Ordering'] = postItem.ordering;
        params[paramName + '___Type'] = postItem.questionnaireItem.type;
      }
    }
  }
  return params;
}

function getVeilederRedirectPath(
  qItems: QuestionnaireItem[],
  qrItems: QuestionnaireResponseItem[],
  redirect: string | undefined
): string | undefined {
  const actionItems = getActiveItemsWithExtension(GUIDANCE_ACTION, qItems, qrItems);
  let url = '';
  if (redirect) {
    url = redirect;
  } else if (actionItems && actionItems.length > 0) {
    const actionExtension = getExtension(GUIDANCE_ACTION, actionItems[0].questionnaireItem);
    url = actionExtension?.valueString || '';
  }
  return url ? toRelativeUrl(decodeURIComponent(url)) : '';
}

function getRedirectParams(
  contextParameter: string | undefined,
  dokumentGuid: string | undefined
): { contextParameter?: string; dokumentGuid?: string } {
  return {
    ...(contextParameter && { contextParameter: contextParameter }),
    ...(dokumentGuid && { dokumentGuid: dokumentGuid }),
  };
}

function submitVeilederData(
  redirectUrl: string,
  postParams: Record<string, string | undefined>,
  contextParameter?: string | undefined,
  dokumentGuid?: string | undefined
): void {
  const contextParamParsed = decodeURIComponent(contextParameter || '')
    .replace(/[^A-Za-z0-9+\-\s]/g, '')
    .trim(); // fjern ulovlige tegn

  if (Object.keys(postParams).length === 0) {
    const params = getRedirectParams(contextParamParsed, dokumentGuid);
    const queryParams = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    let pathMedParams = redirectUrl;
    if (redirectUrl.indexOf('?') > -1 && queryParams) {
      pathMedParams = `${redirectUrl}&${queryParams}`;
    } else if (queryParams) {
      pathMedParams = `${redirectUrl}?${queryParams}`;
    }
    gotoUrl(pathMedParams);
  } else {
    const postParamsMedParametere = {
      ...postParams,
      ...getRedirectParams(contextParamParsed, dokumentGuid),
    };
    postUrl(redirectUrl, postParamsMedParametere);
  }
}

export function redirectEtterFullfortSkjema(
  formDefinition: FormDefinition | null | undefined,
  formData: FormData | null | undefined,
  redirectUrl: string | undefined,
  contextParameter: string | undefined,
  dokumentGuid: string | undefined,
  normalRedirectFn: () => void,
  onCompleteMicroweb?: (formData: Record<string, string | undefined>) => void
): void {
  if (
    !formDefinition ||
    !formDefinition.Content ||
    !formDefinition.Content.item ||
    !formData ||
    !formData.Content ||
    !formData.Content.item
  ) {
    return;
  }

  const qrItems = formData.Content.item;
  const qItems = formDefinition.Content.item;
  const redirectPath = getVeilederRedirectPath(qItems, qrItems, redirectUrl);

  // dersom det finnes redirect url-parameter eller guidanceaction, redirect dit i stedet for innboks/dokumenter
  if (onCompleteMicroweb) {
    const postParams = collectPostParameters(qItems, qrItems);
    onCompleteMicroweb({ redirectPath: redirectPath, documentGuid: dokumentGuid, ...postParams });
  } else if (redirectPath) {
    const postParams = collectPostParameters(qItems, qrItems);
    submitVeilederData(redirectPath, postParams, contextParameter, dokumentGuid);
  } else {
    normalRedirectFn();
  }
}
