import React from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';

import { ResourceHelpItems } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import HjelpetriggerComponent from '@helsenorge/core-framework/prosesshjelp/hjelpetrigger/hjelpetrigger-component';

import { getLoginUrl } from '../../util';

export const replaceLinks = (messageText: string, resources: HNSkjemaFrontend, openSidebar: () => void): JSX.Element => {
  const words = messageText?.split(' ');
  const elementArray = words?.map((word, i) => {
    if (word.indexOf('{omSkjemaLink}') > -1) {
      return (
        <React.Fragment key={i}>
          <span id="anchor_title_helptrigger" className="atom_helptrigger-container">
            <HjelpetriggerComponent
              resources={{ helpTriggerLabel: resources.hjelpeknapp_ariaLabel } as ResourceHelpItems}
              stopPropagation
              onClick={openSidebar}
            />
          </span>
        </React.Fragment>
      );
    } else if (word.indexOf('{loginLink}') > -1) {
      return (
        <React.Fragment key={i}>
          <a href={getLoginUrl()}>{word.replace('{loginLink}', resources.loggeInnLinkText)}</a>
          {` `}
        </React.Fragment>
      );
    } else if (word.indexOf('{personverninnstillingerLink}') > -1) {
      return (
        <React.Fragment key={i}>
          <a href="/personverninnstillinger">{word.replace('{personverninnstillingerLink}', resources.personverninnstillingerLink)}</a>
          {` `}
        </React.Fragment>
      );
    }
    return <React.Fragment key={i}>{`${word} `}</React.Fragment>;
  });
  return <div className={`page_refero__topmessage  ${designsystemtypography['anchorlink-wrapper']}`}>{elementArray}</div>;
};
