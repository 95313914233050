import { HNSkjemaFrontend } from '../types/Resources';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import defaultResource from '../resources/HN.Skjema.Frontend.nb-NO.json';

import { sjekkInputErIkkeTom } from '.';

export const stringToLanguageLocales = (languageString: string): LanguageLocales => {
  switch (languageString) {
    case LanguageLocales.NORWEGIAN_NYNORSK.toLowerCase():
      return LanguageLocales.NORWEGIAN_NYNORSK;
    case LanguageLocales.ENGLISH.toLowerCase():
      return LanguageLocales.ENGLISH;
    case LanguageLocales.LITHUANIAN.toLowerCase():
      return LanguageLocales.LITHUANIAN;
    case LanguageLocales.POLISH.toLowerCase():
      return LanguageLocales.POLISH;
    case LanguageLocales.RUSSIAN.toLowerCase():
      return LanguageLocales.RUSSIAN;
    case LanguageLocales.ROMANIAN.toLowerCase():
      return LanguageLocales.ROMANIAN;
    case LanguageLocales.SAMI_NORTHERN.toLowerCase():
      return LanguageLocales.SAMI_NORTHERN;
    default:
      return LanguageLocales.NORWEGIAN;
  }
};

export const hentOversettelse = (key: string, resources: HNSkjemaFrontend): string => {
  const value = resources[key];
  return sjekkInputErIkkeTom(value) ? value : defaultResource[key];
};
