import { SessionTimeoutAction, ShowSignOutBoxData } from '@helsenorge/framework-utils/types/entities';
import {
  HNeventSetSimplifiedHeader,
  HNeventSetSimplifiedFooter,
  HNeventSetOnShowSignoutbox,
} from '@helsenorge/framework-utils/web-component/events';

export const dispatchSetSimplifiedHeaderAndFooterEvent = (): void => {
  HNeventSetSimplifiedHeader(true);
  HNeventSetSimplifiedFooter('', true);
};

export const dispatchSetSessionTimeoutFn = (fn: (data: ShowSignOutBoxData) => SessionTimeoutAction): void => {
  HNeventSetOnShowSignoutbox(fn);
};
