import { combineReducers, Reducer } from 'redux';

import hjelpeskuff, { HjelpeskuffState } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import { cmsTjenesteTekster, GlobalStateWithCmsTjenesteTekster } from '@helsenorge/framework-utils/cms-tjeneste-tekster';
import { resources, GlobalStateWithResources } from '@helsenorge/framework-utils/resources';

import refero, { HnSkjemautfyllerState } from '../reducers/index';

const rootReducer: Reducer = combineReducers({
  resources,
  hjelpeskuff,
  refero,
  cmsTjenesteTekster,
});

export interface GlobalState extends GlobalStateWithResources, GlobalStateWithCmsTjenesteTekster {
  hjelpeskuff: HjelpeskuffState;
  refero: HnSkjemautfyllerState;
}

export default rootReducer;
