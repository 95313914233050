import {
  Meta,
  Coding,
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer,
} from 'fhir/r4';

import { Metadata } from '../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../types/Resources';

import { getQueryStrings } from '@helsenorge/core-utils/querystring-utils';
import { HelsenorgeProxyError } from '@helsenorge/framework-utils/hn-proxy-service';
import ItemType from '@helsenorge/refero/constants/itemType';
import { ProcedureStatusCodes } from '@helsenorge/refero/types/fhirEnums';
import { getResponseItemAndPathWithLinkId, getQuestionnaireItemsWithType } from '@helsenorge/refero/util/refero-core';

import { getAccessibilityToResponse, getGeneratePdfExtensionValue, getRecipientUrl } from './extension';
import { hentOversettelse } from './stringToLanguageLocales';
import { AccessibilityToResponse } from '../constants/accessibilityToResponse';
import { FEIL, LOGGETUT_FEILCODE, SKJEMA_URL, TQQC, VEILEDER_URL } from '../constants/constants';
import { ForbudBegrunnelser } from '../constants/topMessageVisibility';

interface QueryStrings {
  Query?: string;
  resourceId?: string;
  taskId?: string;
  contextId?: string;
  returURL?: string;
  redirect?: string;
  contextParameter?: string;
}

export function getQueryParams(): QueryStrings {
  return getQueryStrings();
}

export function toRelativeUrl(url: string): string {
  const a = document.createElement('a');
  a.href = url;
  const firstLetter = a.pathname.charAt(0);
  if (firstLetter === '/') {
    return a.pathname + a.search;
  } else {
    return '/' + a.pathname + a.search;
  }
}

export function getLoginUrl(): string {
  return `/auth/signin?Source=${encodeURIComponent(window.location.href)}`;
}

// hent teknisk navn på skjema fra url. Brukes dersom skjemautfyller/veileder åpnes med nytt url-format
export function getSkjemaNameFromUrl(): string {
  const pathParts = window.location.pathname.split('/');
  const pageIndex = pathParts.findIndex(value => {
    if (value?.toLocaleLowerCase()?.startsWith(SKJEMA_URL) || value === VEILEDER_URL) return true;
    return false;
  });

  if (pageIndex === -1) return '';

  const tekniskNavn = pathParts[pageIndex + 1] || '';

  // handle cases where skjemautfyller is repeated, eg.: http://.../skjemautfyller/skjemautfyller/?Query
  if (tekniskNavn === SKJEMA_URL) {
    return '';
  }

  return tekniskNavn;
}

export function hasEndpointOrTqqcComponent(questionnaire: Questionnaire): boolean {
  // sjekk om sdf-endpoint er definert som extension
  if (getRecipientUrl(questionnaire)) {
    return true;
  }
  // ellers, let gjennom skjema etter item med code der system === "http://ehelse.no/fhir/ValueSet/TQQC" og code === "1"
  const reduceHasTqqc = (currentValue: boolean, qItem: QuestionnaireItem): boolean => {
    const childItemHasTqqcComponent = qItem.item ? qItem.item.reduce(reduceHasTqqc, currentValue) : false;
    return (
      (qItem.code && qItem.code.filter((x: Coding) => x.system === TQQC && x.code === '1').length > 0) ||
      currentValue ||
      childItemHasTqqcComponent
    );
  };
  return questionnaire.item ? questionnaire.item.reduce(reduceHasTqqc, false) : false;
}

export function hasExtension(questionnaire: Questionnaire | undefined, extensionUrl: string): boolean {
  if (!questionnaire) return false;
  // Let gjennom skjema etter item med extension som er lik innsendt url
  const reduceExtension = (currentValue: boolean, qItem: QuestionnaireItem): boolean => {
    const childItemHasGuidanceAction = qItem.item ? qItem.item.reduce(reduceExtension, currentValue) : false;
    return (
      (qItem.extension && qItem.extension.filter(item => item.url === extensionUrl).length > 0) ||
      currentValue ||
      childItemHasGuidanceAction
    );
  };
  return questionnaire.item ? questionnaire.item.reduce(reduceExtension, false) : false;
}

export function hasEndpoint(questionnaire: Questionnaire | undefined): boolean {
  if (!questionnaire) return false;
  if (getRecipientUrl(questionnaire)) return true;
  return false;
}

export function isKopiLagret(questionnaire: Questionnaire): boolean {
  const accessibilityToResponse = getAccessibilityToResponse(questionnaire);
  const generatepdf = getGeneratePdfExtensionValue(questionnaire);
  return accessibilityToResponse !== AccessibilityToResponse.Recipient && generatepdf !== false;
}

function getNumberOfAttachments(questionnaireResponse: QuestionnaireResponse): number {
  // let gjennom questionnaireResponse etter item med type === attachment, og tell antall answer.valueAttachment
  const reduceMaxAttachments = (currentMaxAttachments: number, qrItem: QuestionnaireResponseItem): number => {
    const childItemAttachments = qrItem.item ? qrItem.item.reduce(reduceMaxAttachments, 0) : 0;
    const itemAnswerAttachments = qrItem.answer
      ? qrItem.answer.filter((x: QuestionnaireResponseItemAnswer) => !!x.valueAttachment).length
      : 0;
    const itemAnswerItemAttachments = qrItem.answer
      ? qrItem.answer.reduce((current: number, answerItem: QuestionnaireResponseItemAnswer): number => {
          const inner = answerItem.item ? answerItem.item.reduce(reduceMaxAttachments, 0) : 0;
          return inner + current;
        }, 0)
      : 0;
    return childItemAttachments + itemAnswerAttachments + itemAnswerItemAttachments + currentMaxAttachments;
  };
  return questionnaireResponse.item ? questionnaireResponse.item.reduce(reduceMaxAttachments, 0) : 0;
}

export function hasTooManyAttachments(questionnaireResponse: QuestionnaireResponse | null | undefined): boolean {
  if (!questionnaireResponse) {
    return false;
  }
  return getNumberOfAttachments(questionnaireResponse) > 3;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sortObjectByKey(value: QuestionnaireResponseItem[] | QuestionnaireResponseItem): Record<string, any> {
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.map(sortObjectByKey);
    } else {
      return Object.keys(value)
        .sort()
        .reduce((obj, key) => {
          const v = value[key];
          obj[key] = sortObjectByKey(v);
          return obj;
        }, {});
    }
  } else {
    return value;
  }
}

export function sjekkInputErIkkeTom(input: object | string | undefined): boolean {
  return input !== null && input != undefined && input !== '';
}

export function jsonStringifySortProps(qrItem: QuestionnaireResponseItem[]): string {
  return JSON.stringify(sortObjectByKey(qrItem));
}

export function brukerHarIkkeTilgangOgKanPrinteSkjema(metaData: Metadata, printReferanse: string | undefined): boolean {
  return !metaData.HasTilgang && sjekkInputErIkkeTom(printReferanse);
}

export function brukerHarIkkeTilgangTilSkjema(metaData: Metadata, printReferanse: string | undefined): boolean {
  return !metaData.HasTilgang && !printReferanse;
}

function resetQuestionnaireResponseVersion(qr: QuestionnaireResponse, metadata: Metadata): void {
  if (metadata.IsNewDocument && qr.meta) {
    const newMeta = { ...qr.meta, versionId: '1' } as Meta;
    qr.meta = newMeta;
  }
}

function settDokumentStatusTilKladd(qr: QuestionnaireResponse, metadata: Metadata): void {
  if (metadata.IsNewDocument) {
    qr.status = ProcedureStatusCodes.IN_PROGRESS;
  }
}

export function oppdaterQuestionnaireResponseSomNyReponseHvisGjenbrukes(qr: QuestionnaireResponse, metadata: Metadata | undefined): void {
  if (metadata) {
    resetQuestionnaireResponseVersion(qr, metadata);
    settDokumentStatusTilKladd(qr, metadata);
  }
}

export function vedleggMaFjernes(metadata: Metadata): boolean {
  return metadata.HarVedlegg && metadata.IsNewDocument && metadata.HarInnbyggerResponse;
}

export function kanIkkeAutofylleskjema(metadata: Metadata): boolean {
  return metadata.IsNewDocument && !metadata.HarInnbyggerResponse;
}

function removeAnswerFromQuestionnaireResponse(qrItem: QuestionnaireResponseItem, linkId: string): void {
  const itemsWithPath = getResponseItemAndPathWithLinkId(linkId, qrItem);
  itemsWithPath.forEach(ip => delete ip.item.answer);
}

function removeAnswersFromQustionnaireResponse(qr: QuestionnaireResponse, itemsWithType: Array<QuestionnaireItem>): void {
  itemsWithType.forEach(itemWithType => {
    if (qr.item) {
      qr.item.forEach(responseItem => removeAnswerFromQuestionnaireResponse(responseItem, itemWithType.linkId));
    }
  });
}

export function fjernVedleggFraDokumentHvisGjenbrukes(q: Questionnaire, qr: QuestionnaireResponse, metadata: Metadata): void {
  if (vedleggMaFjernes(metadata)) {
    const itemsWithType = getQuestionnaireItemsWithType(ItemType.ATTATCHMENT, q.item);
    if (itemsWithType) {
      removeAnswersFromQustionnaireResponse(qr, itemsWithType);
    }
  }
}

export function getFeilmelding(error: HelsenorgeProxyError): string {
  if (error.response?.code === LOGGETUT_FEILCODE) {
    return LOGGETUT_FEILCODE;
  }
  if (error.response?.message) {
    return error.response.message;
  }
  return FEIL;
}

export function hentSotHarIkkeTilgangFeilmelding(forbudGrunnelse: ForbudBegrunnelser, resource: HNSkjemaFrontend): string {
  switch (forbudGrunnelse) {
    case ForbudBegrunnelser.ScopeRepresentasjonsforholdSegSelv:
      return hentOversettelse('harIkkeTilgang_scop_segselv', resource);
    case ForbudBegrunnelser.ScopeRepresentasjonsforholdFullmakt:
      return hentOversettelse('harIkkeTilgang_scop_fullmakt', resource);
    case ForbudBegrunnelser.SkjemaTilpassetKunInnbygger:
      return hentOversettelse('harIkkeTilgang_skjema_kunInnbygger', resource);
    case ForbudBegrunnelser.SkjemaTilpassetIkkeFullmakt:
      return hentOversettelse('harIkkeTilgang_skjema_kanIkkeFullmakt', resource);
    case ForbudBegrunnelser.SkjemaTilpassetIkkePaVegnAvBarn:
      return hentOversettelse('harIkkeTilgang_skjema_paVegnAvBarn', resource);
    case ForbudBegrunnelser.SkjemaTilpassetIkkePaVegnAvBarnUnder12:
      return hentOversettelse('harIkkeTilgang_skjema_paVegnAvBarnUnder12', resource);
    case ForbudBegrunnelser.SkjemaTilpassetIkkePaVegnAvBarnMellom12Og16:
      return hentOversettelse('harIkkeTilgang_skjema_paVegnAvBarnMellom12Og16', resource);
    case ForbudBegrunnelser.SperretAdresse:
      return hentOversettelse('harIkkeTilgang_sperretadresse', resource);
    case ForbudBegrunnelser.SkjemaTilpassetIkkeUngdom:
      return hentOversettelse('harikkeTilgang_skjema_ungdom', resource);
    default:
      return hentOversettelse('harIkkeTilgang_default', resource);
  }
}
