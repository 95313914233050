import 'core-js/es/object'; // Adds polyfill for `Object` so `Object.isFrozen` (used by Immer) works in IE11

import * as React from 'react';

import { render } from 'react-dom';

import { captureErrors } from '@helsenorge/framework-utils/logger';
import { configureStoreWithMiddleware } from '@helsenorge/framework-utils/store';

import Root from './root/index';
import rootReducer from './root/reducer';

// Capture errors
captureErrors();

// Configure the Redux store
const store = configureStoreWithMiddleware(rootReducer);

// Get the DOM anchor element where the React app will mount
const anchor = document.getElementById('main-content-wrapper');

// Function to render the app
function renderApp(): void {
  if (anchor) {
    const root: React.JSX.Element = <Root store={store} />;
    render(root, anchor);
  }
}

if (process.env.NODE_ENV === 'development' && import.meta.env.MODE === 'development') {
  // Dynamically import the mocks for development only if MODE is set to 'mocks'
  import('./mocks/browser')
    .then(({ worker }) =>
      worker.start({
        onUnhandledRequest: 'bypass',
      })
    )
    .then(() => renderApp()); // Render the app after starting the worker
} else {
  renderApp(); // Render the app immediately in other environments
}
