import * as React from 'react';

import { useSearchParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { Language } from '../types/Language';

import { LanguagePicker } from '@helsenorge/core-framework/language-picker';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { getIsoLanguageName, setDocumentLanguage } from '@helsenorge/framework-utils/hn-language';

import { userChangeLanguage, SkjemaAction } from '../actions/index';
import { LANGUAGE_Code } from '../constants/constants';
import { HnGlobalState } from '../reducers';
import { getDefaultLanguage } from '../util/portalLanguage-utils';

const sortOrder = {
  'nb-no': 1,
  'nn-no': 2,
  'se-no': 3,
  'en-gb': 4,
  unknown: 100, // ukjente språk havner bakerst
};

interface Props {
  languages?: Array<Language> | null;
  dispatch?: ThunkDispatch<HnGlobalState, void, SkjemaAction>;
}

const LanguageSelector: React.FC<Props> = ({ languages, dispatch }: Props) => {
  if (!languages || languages.length <= 1 || !dispatch) {
    return <></>;
  }

  const [searchParams, setSearchParams] = useSearchParams();

  const changeUrlDocumentLanguage = (language: LanguageLocales): void => {
    if (searchParams.has(LANGUAGE_Code)) {
      searchParams.delete(LANGUAGE_Code);
    }
    searchParams.set(LANGUAGE_Code, language);
    setSearchParams(searchParams);
  };

  const selectedLanguage = getDefaultLanguage(languages);

  if (getIsoLanguageName(selectedLanguage) != document.documentElement.lang) {
    setDocumentLanguage(selectedLanguage);
  }

  React.useEffect(() => {
    if (!searchParams.has(LANGUAGE_Code)) {
      searchParams.set(LANGUAGE_Code, selectedLanguage);
      setSearchParams(searchParams);
    }
  }, []);

  const sortedLanguages = [...languages];
  sortedLanguages.sort((a: Language, b: Language) => {
    const sortA = sortOrder[a.Language.toLowerCase()] || sortOrder.unknown;
    const sortB = sortOrder[b.Language.toLowerCase()] || sortOrder.unknown;
    return sortA - sortB;
  });

  return (
    <LanguagePicker
      className="page_refero__languge-selector"
      selectedLanguage={selectedLanguage}
      languages={sortedLanguages.map(x => {
        return { label: x.Display, locale: x.Language as LanguageLocales };
      })}
      changeLanguage={(language: LanguageLocales): void => {
        changeUrlDocumentLanguage(language);
        dispatch(userChangeLanguage(language));
      }}
    />
  );
};

export default LanguageSelector;
