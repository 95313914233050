import React, { FunctionComponent } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';

import { getLoginUrl } from '../util';

interface SkjemaLoginButtonProps {
  buttonText: string;
}

const SkjemaLoginButton: FunctionComponent<SkjemaLoginButtonProps> = ({ buttonText }) => {
  return (
    <Button
      onClick={(): void => {
        window.history.replaceState(window.history.state, '', getLoginUrl());
        window.location.reload();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SkjemaLoginButton;
