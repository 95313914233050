import { Questionnaire, Coding } from 'fhir/r4';

export const getLanguageDisplay = (formDef: Questionnaire): string | undefined => {
  let display = formDef.language;
  if (formDef.meta && formDef.meta.tag) {
    const codings = formDef.meta.tag.filter((t: Coding) => t.system === 'urn:ietf:bcp:47');
    display = codings.length > 0 ? codings[0].display : undefined;
  }
  return display;
};
