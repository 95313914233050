const MELDINGER_BASE_URL = '/meldinger';
const DOKUMENTER_BASE_URL = '/dokumenter';

export function redirectTilInnsendtSkjemaUrl(id: string): string {
  return `${MELDINGER_BASE_URL}/samtale/${id}`;
}

export function redirectTilLagretSkjemaPaaDokumenterUrl(id: string | undefined): string {
  if (id) {
    return `${DOKUMENTER_BASE_URL}/vellykketlagring?dokument=${id}`;
  } else {
    return DOKUMENTER_BASE_URL;
  }
}
