import React, { FunctionComponent } from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

interface Props {
  resources: HNSkjemaFrontend;
  onContinue: () => void;
  onLeave: () => void;
}

const ConfirmationLightboxLagre: FunctionComponent<Props> = ({ resources, onContinue, onLeave }) => {
  return (
    <Modal
      title={resources.lagreSkjemaTittel}
      description={resources.lagreSkjemaDokumenterBody}
      primaryButtonText={resources.lagreSkjemaButtonFortsettUtfylling}
      secondaryButtonText={resources.lagreSkjemaButtonFortsettSenere}
      onSuccess={onContinue}
      onClose={onLeave}
    />
  );
};

export default ConfirmationLightboxLagre;
