import React from 'react';

import { HNSkjemaFrontend } from '../types/Resources';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

interface SkjemaLoadErrorProps {
  resources: HNSkjemaFrontend;
  loadSkjemaoppgaveErrorMessage: string;
}

const SkjemaLoadError = (props: SkjemaLoadErrorProps): JSX.Element => {
  let description: string | undefined;
  if (props.resources[props.loadSkjemaoppgaveErrorMessage]) {
    description = props.resources[props.loadSkjemaoppgaveErrorMessage];
  } else {
    description = props.resources.loadSkjemaErrorBody;
  }

  return (
    <section className="defaultspacing">
      <NotificationPanel variant="alert" label={props.resources.loadSkjemaErrorTitle}>
        {description}
      </NotificationPanel>
    </section>
  );
};

export default SkjemaLoadError;
