import { combineReducers } from 'redux';

import { ReferoState } from '@helsenorge/refero/reducers';
import form from '@helsenorge/refero/reducers/form';

import ui, { UiState } from './ui';

export interface HnGlobalState {
  refero: HnSkjemautfyllerState;
}

export interface HnSkjemautfyllerState extends ReferoState {
  ui: UiState;
}

export default combineReducers({
  ui,
  form,
});
