/* eslint-disable no-console */
import * as React from 'react';

import classNames from 'classnames';
import { Attachment, Questionnaire, QuestionnaireItem, QuestionnaireResponse } from 'fhir/r4';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { TextMessage, Metadata } from '../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../types/Resources';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icons';
import Save from '@helsenorge/designsystem-react/components/Icons/Save';
import Loader from '@helsenorge/designsystem-react/components/Loader';
import Modal from '@helsenorge/designsystem-react/components/Modal';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Stepper from '@helsenorge/designsystem-react/components/Stepper';
import Title, { TitleAppearances } from '@helsenorge/designsystem-react/components/Title';

import { ResourceHelpItems } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import HjelpetriggerComponent from '@helsenorge/core-framework/prosesshjelp/hjelpetrigger/hjelpetrigger-component';
import Languages from '@helsenorge/core-utils/constants/languages';
import { UploadedFile } from '@helsenorge/file-upload/components/dropzone';
import { trackProsesshjelp } from '@helsenorge/framework-utils/adobe-analytics';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { link } from '@helsenorge/framework-utils/hn-proxy-service';
import { getIsNavigatingToSignOut } from '@helsenorge/framework-utils/hn-user';
import { sanitize } from '@helsenorge/framework-utils/trusted-types';
import { SessionTimeoutAction, ShowSignOutBoxData } from '@helsenorge/framework-utils/types/entities';
import { ReferoContainer } from '@helsenorge/refero/components';
import { FormDefinition, FormData, getFormData, getFormDefinition } from '@helsenorge/refero/reducers/form';
import { OrgenhetHierarki } from '@helsenorge/refero/types/orgenhetHierarki';
import { SidebarItem } from '@helsenorge/refero/types/sidebar';
import { IE11HackToWorkAroundBug187484 } from '@helsenorge/refero/util/hacks';
import { isIE11, getId } from '@helsenorge/refero/util/index';
import { Resources } from '@helsenorge/refero/util/resources';

import EventListener from './eventListener';
import LanguageSelector from './languageselector';
import CancelLightbox from './lightboxes/cancellightbox';
import ConfirmationLightbox from './lightboxes/confirmationlightbox';
import ConfirmationLightboxLagre from './lightboxes/confirmationlightbox-lagre';
import DiscretionLightbox from './lightboxes/discretionLightbox';
import Navigator from './navigator/navigator';
import SkjemaLoadError from './skjemaLoadError';
import SkjemaLoginButton from './skjemaLoginButton';
import TopMessage from './topMessage';
import {
  sendInnSkjema,
  dismissSendInnError,
  dismissLagreError,
  dismissKanIkkeLagre,
  hideAnonymousUploadOfAttachmentDenied,
  lagreSkjema,
  loadValueSet,
  lastMottakere,
  kanIkkeLagreError,
  sendInnSkjemaDone,
} from '../actions/formData';
import {
  fetchSkjemaoppgave,
  abortSkjema,
  dismissAnonymousMessage,
  promptLoginMessage,
  closeContinueOrLeaveMessage,
  shouldShowBeforeUnload as shouldShowBeforeUnloadFn,
  closeContinueOrLeaveMessageAndLeave,
  SkjemaAction,
  loadResources,
  showAttachmentMaxFilesError,
  hideAttachmentMaxFilesError,
  redirectTilInnsendtSkjema,
  gotoMinHelse,
  redirectToReturUrlHvisEksisterer as redirectToReturUrlEllerDokumenter,
} from '../actions/index';
import Sidebar from '../components/sidebar/sidebar';
import { AuthenticationRequirement } from '../constants/authenticationRequirement';
import { DEFAULT_MAX_ATTACHMENT_FILE_SIZE_HN_SKJEMA_BYTES, FEIL, LOGGETUT_FEILCODE } from '../constants/constants';
import { SaveCapabilites } from '../constants/saveCapabilites';
import { SidebarVisibility } from '../constants/sidebarVisibility';
import { TopMessageVisibility } from '../constants/topMessageVisibility';
import { HnGlobalState } from '../reducers';
import { UiState } from '../reducers/ui';
import { GlobalState } from '../root/reducer';
import { hasTooManyAttachments, getQueryParams, isKopiLagret, jsonStringifySortProps, getLoginUrl, getSkjemaNameFromUrl } from '../util';
import { getAuthenticationRequirementValue, getLastSaveCapabilitiesValue } from '../util/extension';
import { getLanguagesInDefinition } from '../util/getLanguagesInDefinition';
import { getStepArrayLength } from '../util/getStepArrayLength';
import { shouldFormBeDisplayedAsStepView } from '../util/shouldFormBeDisplayedAsStepView';
import { hentOversettelse } from '../util/stringToLanguageLocales';
import { trackFormEvent } from '../util/trackFormEvent';
import { redirectEtterFullfortSkjema } from '../util/veileder';
import { dispatchSetSimplifiedHeaderAndFooterEvent, dispatchSetSessionTimeoutFn } from '../util/webcomp-utils';

interface Props {
  skjemaTekniskNavn?: string;
  dokumentGuid?: string;
  isMicroweb?: boolean;
  isMicrowebStep?: boolean;
  formparent?: string;
  onNavigateForwardMicroweb?: (formData: Record<string, string | undefined>) => void;
  onNavigateBackMicroweb?: (formData: Record<string, string | undefined>) => void;
  onCompleteMicroweb?: (formData: Record<string, string | undefined>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancelMicroweb?: (formData: any) => void;
}
interface StateProps {
  formDefinition?: FormDefinition | null;
  resources?: HNSkjemaFrontend;
  formData?: FormData | null;
  lastSavedData?: QuestionnaireResponse;
  sendInnSkjemaError?: string;
  lagreSkjemaError?: string;
  kanIkkeLagre?: boolean;
  skjemaSendtInn?: boolean;
  skjemaSendesInn?: boolean;
  showGlobalSpinner?: boolean;
  showLocalSpinner?: boolean;
  isAttachmentMaxFilesErrorVisible?: boolean;
  loadSkjemaoppgaveWarningMessage?: TextMessage;
  loadSkjemaoppgaveErrorMessage?: string;
  dokumentGuid?: string;
  samtaleGuid?: string;
  blockSubmit?: boolean;
  isSavedInDokumenter?: boolean;
  isNavigatorEnabled?: boolean;
  shouldShowBeforeUnload?: boolean;
  showAnonymousUploadDenied?: boolean;
  showAnonymousSkjemaLightbox?: boolean;
  showContinueOrLeaveMessage?: boolean;
  kanMellomlagres: boolean;
  kanLagresEtterInnsending: boolean;
  printReference?: string;
  userMetadata?: Metadata;
  sidebarData?: Array<SidebarItem>;
  sidebarVisibility?: number;
  topMessageVisibility?: number;
  harGuidanceAction?: boolean;
  harEndepunkt?: boolean;
  questionnaires?: Array<Questionnaire>;
}

interface DispatchProps {
  dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>;
  mount: () => void;
}

interface State {
  showCancelLightbox?: boolean;
  isSidebarOpen: boolean;
  lastActivity: number;
  isNavigatorInitiated: boolean;
  isDiscretionLightboxVisible: boolean;
  isSentWithDiscretion: boolean;
  stepIndex?: number;
  timeSkjemaLoadedForUser: number;
}

class Skjemautfyller extends React.Component<StateProps & DispatchProps & Props, State> {
  constructor(props: StateProps & DispatchProps) {
    super(props);

    this.state = {
      showCancelLightbox: false,
      isSidebarOpen: false,
      lastActivity: Date.now(),
      isNavigatorInitiated: false,
      isDiscretionLightboxVisible: false,
      isSentWithDiscretion: false,
      stepIndex: 0,
      timeSkjemaLoadedForUser: Date.now(),
    };
  }

  componentDidMount(): void {
    this.props.mount();
    dispatchSetSimplifiedHeaderAndFooterEvent();
    if (!this.props.isMicroweb && !this.props.isMicrowebStep) {
      window.addEventListener('beforeunload', this.onUnload);
    }
    document.getElementsByTagName('html')[0].classList.add('page_refero__fixed-scroll-offset');
    this.updateDocumentTitle();
  }

  componentDidUpdate(prevProps: StateProps & DispatchProps): void {
    if (!prevProps.formDefinition?.Content && this.props.formDefinition?.Content) {
      dispatchSetSessionTimeoutFn(this.onShowSignOutBox);
    }
    if (this.props.formDefinition !== prevProps.formDefinition) {
      this.updateDocumentTitle();
    }
    if (
      !isIE11() && // IntersectionObserver støttes ikke i IE11
      this.props.isNavigatorEnabled &&
      !this.state.isNavigatorInitiated &&
      document.querySelectorAll('.mol_form--content > section[id]').length > 0
    ) {
      this.setState({
        isNavigatorInitiated: true,
      });
    }
    IE11HackToWorkAroundBug187484();
  }

  updateDocumentTitle(): void {
    const { formDefinition } = this.props;
    if (formDefinition && formDefinition.Content && formDefinition.Content.title) {
      document.title = formDefinition.Content.title;
    }
  }

  onShowSignOutBox = (data: ShowSignOutBoxData): SessionTimeoutAction => {
    const timeUserHasBeenIdle = Date.now() - this.state.lastActivity;
    const idleRatio = timeUserHasBeenIdle / (data.tokenLifetime - data.tokenWarnUserWhenRemaining);
    if (idleRatio > 0.5) {
      return SessionTimeoutAction.Default;
    }

    return SessionTimeoutAction.RequestExtension;
  };

  onUnload = (e: BeforeUnloadEvent): string | undefined => {
    // Show confirmation dialog if user has unsaved data
    const changed = this.hasChanges();

    if (changed && !getIsNavigatingToSignOut()) {
      const confirmationMessage = 'advarsel';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  hasChanges = (): boolean => {
    let hasChanges = false;
    if (
      this.props.shouldShowBeforeUnload &&
      this.props.formData &&
      this.props.lastSavedData &&
      this.props.lastSavedData.item &&
      this.props.formData.Content &&
      this.props.formData.Content.item &&
      jsonStringifySortProps(this.props.formData.Content.item) !== jsonStringifySortProps(this.props.lastSavedData.item)
    ) {
      hasChanges = true;
    }
    return hasChanges;
  };

  onSubmit = (): void => {
    if (hasTooManyAttachments(this.props.formData?.Content)) {
      this.props.dispatch(showAttachmentMaxFilesError());
    } else {
      if (!this.props.skjemaSendesInn) {
        this.submitForm();
      }
    }
  };

  submitForm = (): void => {
    const formName = this.props.formDefinition?.Content?.name ? this.props.formDefinition?.Content?.name : 'Skjema uten navn';

    const lastSaveCapabilityItemValue = getLastSaveCapabilitiesValue(
      this.props.formDefinition?.Content?.item,
      this.props.formData?.Content?.item
    );
    const itemApprovesSavingOnComplete =
      lastSaveCapabilityItemValue === SaveCapabilites.FinalAndIntermediateVersion ||
      lastSaveCapabilityItemValue === SaveCapabilites.FinalVersionOnly;

    if (
      lastSaveCapabilityItemValue === SaveCapabilites.NoSaving ||
      (!itemApprovesSavingOnComplete && !this.props.kanLagresEtterInnsending)
    ) {
      this.props.samtaleGuid ? this.props.dispatch(sendInnSkjemaDone(this.props.samtaleGuid)) : this.props.dispatch(sendInnSkjemaDone());
      this.props.dispatch(shouldShowBeforeUnloadFn(false));
      this.redirectVeilederData(gotoMinHelse);
    } else if (formName === 'HV-KGBS-1') {
      this.setState({
        isDiscretionLightboxVisible: true,
      });
    } else {
      this.props.dispatch(sendInnSkjema(this.state.timeSkjemaLoadedForUser, false, undefined, this.props.onCompleteMicroweb));
    }
  };

  redirectVeilederData = (normalRedirectFn: () => void): void => {
    // redirect til innboks eller til neste steg i veileder
    const queryParams = getQueryParams();
    redirectEtterFullfortSkjema(
      this.props.formDefinition,
      this.props.formData,
      queryParams.redirect,
      queryParams.contextParameter,
      '',
      normalRedirectFn,
      this.props.onCompleteMicroweb
    );
  };

  onSave = (): void | undefined => {
    const lastSaveCapabilityItemValue = getLastSaveCapabilitiesValue(
      this.props.formDefinition?.Content?.item,
      this.props.formData?.Content?.item
    );

    if (lastSaveCapabilityItemValue === SaveCapabilites.NoSaving || lastSaveCapabilityItemValue === SaveCapabilites.FinalVersionOnly) {
      this.props.dispatch(kanIkkeLagreError());
    } else if (hasTooManyAttachments(this.props.formData?.Content)) {
      this.props.dispatch(showAttachmentMaxFilesError());
    } else {
      this.props.dispatch(lagreSkjema(this.state.timeSkjemaLoadedForUser, false));
    }
  };

  onCancel = (): void => {
    if (this.props.onCancelMicroweb) {
      if (this.props.formData) {
        this.props.onCancelMicroweb(this.props.formData);
      }
    } else if (this.hasChanges()) {
      this.setState({ showCancelLightbox: true });
      this.props.dispatch(shouldShowBeforeUnloadFn(false));
    } else {
      this.onAbortSkjema();
    }
  };

  hideCancelLightbox = (): void => {
    this.setState({ showCancelLightbox: false });
    this.props.dispatch(shouldShowBeforeUnloadFn(true));
  };

  hideDiscretionLightbox = (): void => {
    this.setState({ isDiscretionLightboxVisible: false });
  };

  hideAnonymousSkjemaLightbox = (): void => {
    this.props.dispatch(dismissAnonymousMessage());
  };

  onSendInnErrorClose = (): void => {
    this.props.dispatch(dismissSendInnError());
  };

  onLagreErrorClose = (): void => {
    this.props.dispatch(dismissLagreError());
  };

  onKanIkkeLagreClose = (): void => {
    this.props.dispatch(dismissKanIkkeLagre());
  };

  onAbortSkjema = (): void => {
    this.props.dispatch(abortSkjema());
  };

  registerActivity = (): void => {
    this.setState({ lastActivity: Date.now() });
  };

  generateAttachmentLink = (fileId: string): string => {
    return link('skjemainternal', 'api/v1/Attachment', {
      id: fileId,
    });
  };

  openAttachment = (fileId: string): void => {
    window.open(this.generateAttachmentLink(fileId), '_blank');
  };

  uploadAttachment = (
    files: File[],
    onSuccess: (uploadedFile: UploadedFile, attachment: Attachment) => void,
    onError: (errormessage: TextMessage | null) => void
  ): void => {
    const reader = new FileReader();
    reader.onload = (): void => {
      const dataString = reader.result?.toString() || '';

      const attachment: Attachment = {
        title: files[0].name,
        url: `${getId()}-generated`,
        data: dataString.substring(dataString.indexOf(',') + 1),
        contentType: dataString.substring(dataString.indexOf(':') + 1, dataString.indexOf(';')),
      };

      onSuccess({ id: attachment.url, name: attachment.title || '' }, attachment);
    };
    reader.onerror = (): void => {
      onError({ Title: this.props.resources?.uploadFileError || '', Body: '' });
    };

    reader.readAsDataURL(files[0]);
  };

  deleteAttachment = (_fileId: string, onSuccess: () => void): void => {
    onSuccess();
  };

  getAttachmentLink = (fileId: string): string => {
    return fileId.indexOf('generated') > -1 ? '' : this.generateAttachmentLink(fileId);
  };

  getActionButtonTitle = (resources: HNSkjemaFrontend): string => {
    if (getQueryParams().redirect || (!this.props.harEndepunkt && this.props.harGuidanceAction)) {
      return hentOversettelse('formProceed', resources);
    }
    return this.props.isSavedInDokumenter ? hentOversettelse('formFinish', resources) : hentOversettelse('formSend', resources);
  };

  getSaveFn = (): (() => void) | undefined => {
    return this.props.kanMellomlagres ? this.onSave : undefined;
  };

  erSendOgLagreKnapperDisabled = (): boolean => {
    const hideIfRepresentertSubjectOnly = this.props.userMetadata?.IsRepresentert && this.props.userMetadata?.CanOnlyBePerformedBySubject;
    const hideIfNoTilgang = !this.props.userMetadata?.HasTilgang;
    return isAuthorized() && (hideIfRepresentertSubjectOnly || hideIfNoTilgang);
  };

  getSkjemaErrorTittel = (error: string): string => {
    const { resources } = this.props;
    if (!resources) {
      return FEIL;
    }
    return error === LOGGETUT_FEILCODE
      ? hentOversettelse('loggetut_feil_tittel', resources)
      : hentOversettelse('sendInnSkjemaErrorTitle', resources);
  };

  getSkjemaErrorBody = (error: string): string | undefined => {
    const { isSavedInDokumenter, resources } = this.props;

    if (!resources) {
      return;
    }
    if (error) {
      const errorBody =
        error === LOGGETUT_FEILCODE
          ? hentOversettelse('loggetut_feil_body', resources)
          : hentOversettelse('sendInnSkjemaErrorBody', resources);

      return hentOversettelse(error, resources) || (isSavedInDokumenter ? hentOversettelse('fullforSkjemaFeilBody', resources) : errorBody);
    }
  };

  renderSkjemaTitle = (): JSX.Element | null => {
    const { formDefinition, resources, sidebarVisibility, isMicroweb } = this.props;

    if (!resources || !formDefinition || !formDefinition.Content || !formDefinition.Content.title) {
      return null;
    }
    const appearance: TitleAppearances = isMicroweb ? 'title2' : 'title1';

    return (
      <div className="page_refero__formtitlewrapper">
        <Title htmlMarkup="h1" appearance={appearance} className="page_refero__formtitle" id="sidetittel">
          {formDefinition.Content.title}
        </Title>
        {sidebarVisibility === SidebarVisibility.On && !isMicroweb && (
          <span id="anchor_title_helptrigger" className="atom_helptrigger-container">
            <HjelpetriggerComponent
              resources={{ helpTriggerLabel: hentOversettelse('hjelpeknapp_ariaLabel', resources) } as ResourceHelpItems}
              stopPropagation
              onClick={this.openSidebar}
            />
          </span>
        )}
      </div>
    );
  };

  shouldAllowAnonymous = (authenticationRequirement: AuthenticationRequirement | undefined): boolean => {
    switch (authenticationRequirement) {
      case AuthenticationRequirement.Anonymous:
      case AuthenticationRequirement.Optional:
        return true;
      default:
        return false;
    }
  };

  trackFormError = (): void => {
    trackFormEvent('site error', getSkjemaNameFromUrl());
  };

  getSkjemaResources = (resources: HNSkjemaFrontend): Resources => {
    return {
      ...resources,
      oppgiDatoTid: '', // TODO: brukes ikke, men er påkrevd. Fjern fra npm-pakken
      formSend: this.props.isMicrowebStep ? resources.nextStep : this.getActionButtonTitle(resources),
      formCancel: this.props.isMicrowebStep ? resources.previousStep : resources.formCancel,
      formRequired: '', // brukes ikke i helsenorge-skjema
      clearDate: resources.datepicker_clearDate,
      calendarLabel: resources.datepicker_calendarLabel,
      closeDatePicker: resources.datepicker_closeDatePicker,
      focusStartDate: resources.datepicker_focusStartDate,
      jumpToPrevMonth: resources.datepicker_jumpToPrevMonth,
      jumpToNextMonth: resources.datepicker_jumpToNextMonth,
      keyboardShortcuts: resources.datepicker_keyboardShortcuts,
      showKeyboardShortcutsPanel: resources.datepicker_showKeyboardShortcutsPanel,
      hideKeyboardShortcutsPanel: resources.datepicker_hideKeyboardShortcutsPanel,
      enterKey: resources.datepicker_enterKey,
      leftArrowRightArrow: resources.datepicker_leftArrowRightArrow,
      upArrowDownArrow: resources.datepicker_upArrowDownArrow,
      pageUpPageDown: resources.datepicker_pageUpPageDown,
      homeEnd: resources.datepicker_homeEnd,
      escape: resources.datepicker_escape,
      questionMark: resources.datepicker_questionMark,
      openThisPanel: resources.datepicker_openThisPanel,
      selectFocusedDate: resources.datepicker_selectFocusedDate,
      moveFocusByOneDay: resources.datepicker_moveFocusByOneDay,
      moveFocusByOneWeek: resources.datepicker_moveFocusByOneWeek,
      moveFocusByOneMonth: resources.datepicker_moveFocusByOneMonth,
      moveFocustoStartAndEndOfWeek: resources.datepicker_moveFocustoStartAndEndOfWeek,
      returnFocusToInput: resources.datepicker_returnFocusToInput,
    };
  };

  renderSkjema = (pdf?: boolean): JSX.Element | null => {
    const { formDefinition, resources, dispatch, isMicroweb } = this.props;

    if (!formDefinition || !formDefinition.Content || !resources) {
      return null;
    }

    const skjemaResources: Resources = this.getSkjemaResources(resources);
    const authenticationRequirement = getAuthenticationRequirementValue(formDefinition.Content);
    const saveFn = this.getSaveFn();
    const allowAnonymous = this.shouldAllowAnonymous(authenticationRequirement);
    if (pdf) {
      return (
        <div>
          {this.renderSkjemaTitle()}
          <ReferoContainer
            pdf={true}
            authorized={isAuthorized() || allowAnonymous}
            blockSubmit={this.props.blockSubmit}
            onCancel={this.onCancel}
            onSave={saveFn}
            onSubmit={this.onSubmit}
            loginButton={<SkjemaLoginButton buttonText={resources.skjemaLoginButton} />}
            resources={skjemaResources}
            isMicroweb={this.props.isMicroweb}
            isMicrowebStep={this.props.isMicrowebStep}
          />
        </div>
      );
    }

    const determineTopMessageVisibility = (): number | undefined => {
      const { formDefinition, topMessageVisibility } = this.props;
      const { stepIndex } = this.state;

      const shouldHideTopMessage = formDefinition && shouldFormBeDisplayedAsStepView(formDefinition) && stepIndex && stepIndex > 0;

      return shouldHideTopMessage ? TopMessageVisibility.Off : topMessageVisibility;
    };

    return (
      <div>
        <EventListener
          dispatch={this.props.dispatch}
          timeSkjemaLoadedForUser={this.state.timeSkjemaLoadedForUser}
          automatiskLagering={true}
          kanMellomlagres={this.props.kanMellomlagres}
        />
        <LanguageSelector
          languages={this.props.questionnaires && getLanguagesInDefinition(this.props.questionnaires)}
          dispatch={this.props.dispatch}
        />
        {this.props.formDefinition && !this.props.isMicroweb && shouldFormBeDisplayedAsStepView(this.props.formDefinition) && (
          <Stepper value={this.state.stepIndex} max={getStepArrayLength(this.props.formDefinition)}></Stepper>
        )}
        {this.renderSkjemaTitle()}
        {this.props.userMetadata && (
          <TopMessage
            resources={resources}
            authenticationRequirement={authenticationRequirement}
            userMetadata={this.props.userMetadata}
            printReference={this.props.printReference}
            openSidebar={this.openSidebar}
            visibility={determineTopMessageVisibility()}
          />
        )}
        {this.state.isNavigatorInitiated && <Navigator lastActivity={this.state.lastActivity} />}
        <ReferoContainer
          authorized={isAuthorized() || allowAnonymous}
          blockSubmit={this.props.blockSubmit}
          onCancel={this.onCancel}
          onSave={saveFn}
          onSubmit={this.onSubmit}
          loginButton={isMicroweb ? undefined : <SkjemaLoginButton buttonText={resources.skjemaLoginButton} />}
          resources={skjemaResources}
          promptLoginMessage={(): void => {
            dispatch && dispatch(promptLoginMessage());
          }}
          onRequestAttachmentLink={this.getAttachmentLink}
          onOpenAttachment={this.openAttachment}
          onDeleteAttachment={this.deleteAttachment}
          uploadAttachment={this.uploadAttachment}
          attachmentErrorMessage={hasTooManyAttachments(this.props.formData?.Content) ? resources.attachment_field_max_error : undefined}
          sticky={isMicroweb ? false : true}
          onRequestHelpButton={isMicroweb ? undefined : this.renderHelpButton}
          onRequestHelpElement={isMicroweb ? undefined : this.renderHelpElement}
          validateScriptInjection
          onChange={this.registerActivity}
          fetchValueSet={loadValueSet}
          attachmentMaxFileSize={DEFAULT_MAX_ATTACHMENT_FILE_SIZE_HN_SKJEMA_BYTES}
          autoSuggestProps={{
            minSearchCharacters: 3,
            typingSearchDelay: 500,
          }}
          submitButtonDisabled={!!this.props.loadSkjemaoppgaveWarningMessage || this.erSendOgLagreKnapperDisabled()}
          saveButtonDisabled={this.erSendOgLagreKnapperDisabled()}
          fetchReceivers={(successCallback: (receivers: Array<OrgenhetHierarki>) => void, errorCallback: () => void): void => {
            lastMottakere(formDefinition.Content?.name || '', successCallback, errorCallback);
          }}
          onFieldsNotCorrectlyFilledOut={this.trackFormError}
          onStepChange={(newIndex: number): void => this.setState({ stepIndex: newIndex })}
        />
        {!isMicroweb && !shouldFormBeDisplayedAsStepView(formDefinition) && isAuthorized() && this.props.kanMellomlagres && saveFn && (
          <div className="extra_savebutton_wrapper">
            <Button onClick={saveFn} disabled={this.erSendOgLagreKnapperDisabled()} wrapperClassName="extra_savebutton" variant="outline">
              <Icon svgIcon={Save}></Icon>
              {resources.formSave}
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderHelpButton = (
    _item: QuestionnaireItem,
    _helpItem: QuestionnaireItem,
    _helpType: string,
    _helpText: string,
    opening: boolean
  ): JSX.Element => {
    const { resources } = this.props;

    return (
      <button
        type="button"
        title={resources ? resources.helpButtonTooltip : undefined}
        className="atom_inline-functionbutton"
        aria-expanded={opening}
      />
    );
  };

  renderHelpElement = (
    _item: QuestionnaireItem,
    _helpItem: QuestionnaireItem,
    _helpType: string,
    helpText: string,
    opening: boolean
  ): JSX.Element => {
    const collapseClasses: string = classNames({
      page_refero__helpComponent: true,
      'page_refero__helpComponent--open': opening,
    });
    return opening ? <SafeHTML className={collapseClasses} html={helpText} tagName="div"></SafeHTML> : <></>;
  };

  onCloseLagreMessageAndContinue = (): void => {
    this.props.dispatch(closeContinueOrLeaveMessage());
  };

  onCloseLagreMessageAndLeave = (): void => {
    this.props.dispatch(closeContinueOrLeaveMessageAndLeave());

    this.props.dispatch(redirectToReturUrlEllerDokumenter());
  };

  onAnonymousUploadDeniedClose = (): void => {
    this.props.dispatch(hideAnonymousUploadOfAttachmentDenied());
  };

  openSidebar = (): void => {
    this.toggleSidebarOpen(true);
  };

  closeSidebar = (): void => {
    this.toggleSidebarOpen(false);
  };

  toggleSidebarOpen = (isOpen: boolean): void => {
    trackProsesshjelp('Prosesshjelp', 'Hjelp', 'Om skjema', isOpen ? 'Open' : 'Close');
    this.setState({
      isSidebarOpen: isOpen,
    });
  };

  renderSidebar = (): JSX.Element | null => {
    if (
      !this.props.resources ||
      !this.props.sidebarData ||
      !this.props.formDefinition ||
      !this.props.formDefinition.Content ||
      !this.props.formDefinition.Content.title ||
      !this.props.userMetadata ||
      this.props.sidebarVisibility === SidebarVisibility.Off
    ) {
      return null;
    }

    return (
      <Sidebar
        isOpen={this.state.isSidebarOpen}
        toggleShowSidebar={(): void => this.toggleSidebarOpen(!this.state.isSidebarOpen)}
        sidebarData={this.props.sidebarData}
        hideSidebar={this.closeSidebar}
        showSidebar={this.openSidebar}
        resources={this.props.resources}
        formTitle={this.props.formDefinition.Content.title}
        kanSendes={!this.props.isSavedInDokumenter}
        kanLagres={this.props.kanLagresEtterInnsending}
        harRepresentasjonsMulighet={isAuthorized() && !this.props.userMetadata.CanOnlyBePerformedBySubject}
        printReference={isAuthorized() ? undefined : this.props.printReference}
      />
    );
  };

  render(): JSX.Element | null {
    const {
      resources,
      showGlobalSpinner,
      showLocalSpinner,
      sendInnSkjemaError,
      skjemaSendtInn,
      lagreSkjemaError,
      showAnonymousUploadDenied,
      showAnonymousSkjemaLightbox,
      showContinueOrLeaveMessage,
      samtaleGuid,
      loadSkjemaoppgaveWarningMessage,
      loadSkjemaoppgaveErrorMessage,
      isAttachmentMaxFilesErrorVisible,
      kanIkkeLagre,
      isMicroweb,
    } = this.props;

    if (!resources) {
      return null;
    }

    if (loadSkjemaoppgaveErrorMessage) {
      return (
        <div>
          <SkjemaLoadError resources={resources} loadSkjemaoppgaveErrorMessage={loadSkjemaoppgaveErrorMessage} />
        </div>
      );
    }

    return (
      <div>
        <div className="page_refero__content">
          {showGlobalSpinner && <Loader size={'large'} overlay={'screen'} color="black" />}
          {showLocalSpinner && (
            <div className="page_refero__spinnerspacing">
              <Loader size={'large'} />
            </div>
          )}
          {loadSkjemaoppgaveWarningMessage && (
            <section className="defaultspacing">
              <NotificationPanel
                variant="warn"
                label={sanitize(
                  resources[loadSkjemaoppgaveWarningMessage.Title]
                    ? resources[loadSkjemaoppgaveWarningMessage.Title]
                    : loadSkjemaoppgaveWarningMessage.Title
                )}
              >
                {resources[loadSkjemaoppgaveWarningMessage.Body]
                  ? resources[loadSkjemaoppgaveWarningMessage.Body]
                  : loadSkjemaoppgaveWarningMessage.Body}
              </NotificationPanel>
            </section>
          )}
          {this.renderSkjema()}
        </div>
        {!isMicroweb && this.renderSidebar()}
        {showAnonymousSkjemaLightbox && !isAuthorized() && (
          <Modal
            title={'Logg inn'}
            primaryButtonText={resources.skjemaLoginButton}
            secondaryButtonText={resources.formCancel}
            onSuccess={(): void => {
              window.history.replaceState(window.history.state, '', getLoginUrl());
              window.location.reload();
            }}
            onClose={this.hideAnonymousSkjemaLightbox}
          >
            <NotificationPanel variant="info">{resources.skjemaLoginMessageAuthenticationRequirementRequired}</NotificationPanel>
          </Modal>
        )}
        {sendInnSkjemaError && (
          <Modal
            onClose={this.onSendInnErrorClose}
            title={this.getSkjemaErrorTittel(sendInnSkjemaError)}
            description={this.getSkjemaErrorBody(sendInnSkjemaError)}
            onSuccess={this.onSendInnErrorClose}
            primaryButtonText={resources.confirmationActionButton}
          />
        )}
        {lagreSkjemaError && (
          <Modal
            onClose={this.onLagreErrorClose}
            title={this.getSkjemaErrorTittel(lagreSkjemaError)}
            description={this.getSkjemaErrorBody(lagreSkjemaError)}
            onSuccess={this.onLagreErrorClose}
            primaryButtonText={resources.confirmationActionButton}
          />
        )}
        {kanIkkeLagre && (
          <Modal
            onClose={this.onKanIkkeLagreClose}
            title={resources.kanIkkeLagreErrorTitle}
            description={resources.kanIkkeLagreErrorBody}
            onSuccess={this.onKanIkkeLagreClose}
            primaryButtonText={resources.confirmationActionButton}
          />
        )}
        {this.state.showCancelLightbox && (
          <CancelLightbox hideCancelLightbox={this.hideCancelLightbox} resources={resources} onAbortSkjema={this.onAbortSkjema} />
        )}
        {skjemaSendtInn && samtaleGuid && (
          <ConfirmationLightbox
            resources={resources}
            onOkButtonClick={(): void => {
              this.redirectVeilederData(() => redirectTilInnsendtSkjema(samtaleGuid));
            }}
            erKopiLagret={
              !this.state.isSentWithDiscretion &&
              (this.props.formDefinition?.Content ? isKopiLagret(this.props.formDefinition?.Content) : true)
            }
          />
        )}
        {showContinueOrLeaveMessage && (
          <ConfirmationLightboxLagre
            resources={resources}
            onContinue={this.onCloseLagreMessageAndContinue}
            onLeave={this.onCloseLagreMessageAndLeave}
          />
        )}
        {this.state.isDiscretionLightboxVisible && (
          <DiscretionLightbox
            resources={resources}
            closeCallback={this.hideDiscretionLightbox}
            sendCallback={(withDiscretion?: boolean): void => {
              this.hideDiscretionLightbox();
              this.setState({
                isSentWithDiscretion: withDiscretion || false,
              });
              this.props.dispatch(sendInnSkjema(this.state.timeSkjemaLoadedForUser, false, withDiscretion));
            }}
          />
        )}
        {showAnonymousUploadDenied && (
          <Modal
            title={resources.anonymousUploadTitle}
            onClose={this.onAnonymousUploadDeniedClose}
            onSuccess={this.onAnonymousUploadDeniedClose}
          >
            <NotificationPanel variant="info">{resources.anonymousUploadDescription}</NotificationPanel>
          </Modal>
        )}
        {isAttachmentMaxFilesErrorVisible && (
          <Modal
            title={'Teknisk feil'}
            onClose={(): void => {
              this.props.dispatch(hideAttachmentMaxFilesError());
            }}
            onSuccess={(): void => {
              this.props.dispatch(hideAttachmentMaxFilesError());
            }}
            primaryButtonText={resources.confirmationActionButton}
          >
            <NotificationPanel variant="alert">{resources.attachment_field_max_error}</NotificationPanel>
          </Modal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: GlobalState): StateProps {
  const {
    resources,
    lastSavedData,
    sendInnSkjemaError,
    showGlobalSpinner,
    showLocalSpinner,
    skjemaSendtInn,
    skjemaSendesInn,
    loadSkjemaoppgaveErrorMessage,
    loadSkjemaoppgaveWarningMessage,
    isAttachmentMaxFilesErrorVisible,
    lagreSkjemaError,
    kanIkkeLagre,
    blockSubmit,
    shouldShowBeforeUnload,
    isSavedInDokumenter,
    isNavigatorEnabled,
    showAnonymousUploadDenied,
    showAnonymousSkjemaLightbox,
    showContinueOrLeaveMessage,
    dokumentGuid,
    samtaleGuid,
    kanMellomlagres,
    kanLagresEtterInnsending,
    printReference,
    userMetadata,
    sidebarData,
    sidebarVisibility,
    topMessageVisibility,
    harGuidanceAction,
    harEndepunkt,
    questionnaires,
  }: UiState = state.refero.ui;

  return {
    resources: resources,
    formDefinition: getFormDefinition(state),
    formData: getFormData(state),
    lastSavedData: lastSavedData,
    showGlobalSpinner,
    showLocalSpinner,
    sendInnSkjemaError,
    skjemaSendtInn,
    skjemaSendesInn,
    loadSkjemaoppgaveErrorMessage,
    loadSkjemaoppgaveWarningMessage,
    isAttachmentMaxFilesErrorVisible,
    lagreSkjemaError,
    kanIkkeLagre,
    blockSubmit,
    shouldShowBeforeUnload,
    isSavedInDokumenter,
    isNavigatorEnabled,
    showAnonymousUploadDenied,
    showAnonymousSkjemaLightbox,
    showContinueOrLeaveMessage,
    dokumentGuid,
    samtaleGuid,
    kanMellomlagres,
    kanLagresEtterInnsending,
    printReference,
    userMetadata,
    sidebarData,
    sidebarVisibility,
    topMessageVisibility,
    harGuidanceAction,
    harEndepunkt,
    questionnaires,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>, props: Props): DispatchProps {
  return {
    mount: (): void => {
      dispatch(loadResources(Languages.NORWEGIAN.toLowerCase()));
      dispatch(fetchSkjemaoppgave(props.skjemaTekniskNavn, props.dokumentGuid, props.isMicroweb, props.formparent));
    },
    dispatch,
  };
}

export default connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps)(Skjemautfyller);
