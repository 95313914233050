import * as React from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icons';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';

import { getSections } from '../../util/getSections';

interface FormDefinitionProps {
  lastActivity: number;
}

const Navigator = (props: FormDefinitionProps): React.ReactElement => {
  const [sections, setSections] = React.useState(getSections());
  const [sectionNameToBeDisplayed, setSectionNameToBeDisplayed] = React.useState('');
  const [isNavigatorMenuExpanded, setIsNavigatorMenuExpanded] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const newSections = getSections();
    setSections(newSections);
    if (newSections.length > 0 && sectionNameToBeDisplayed === '') {
      setSectionNameToBeDisplayed(newSections[0].name);
    } else {
      setSectionNameToBeDisplayed('');
    }
  }, [props.lastActivity]);

  if (sections.length === 0) {
    return <></>;
  } else {
    const scrollToSelectedSection = (sectionId: string): void => {
      window.location.href = `#${sectionId}-navanchor`;
      window.scrollBy(0, -150);
    };

    const toggleNavigatorExpand = (): void => {
      setIsNavigatorMenuExpanded(!isNavigatorMenuExpanded);
    };

    const renderNavigatorMenu = (): React.ReactElement => {
      return (
        <div ref={wrapperRef} className="page_refero__navigatormenu">
          {sections.length > 0 &&
            sections.map(x => {
              if (x.name) {
                return (
                  <div key={x.id}>
                    <Button
                      variant="borderless"
                      className="page_refero__navmenubutton"
                      onClick={(): void => {
                        scrollToSelectedSection(x.id);
                        setSectionNameToBeDisplayed(x.name);
                        toggleNavigatorExpand();
                      }}
                    >
                      {x.name}
                    </Button>
                  </div>
                );
              }
            })}
        </div>
      );
    };

    const getYCoordinates = (element: HTMLElement | null): number | undefined => {
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top + window.scrollY;
      }
    };

    window.addEventListener('scroll', function () {
      const scroll = this.scrollY;
      const navigator = document.getElementsByClassName('page_refero__navigator') as HTMLCollectionOf<HTMLElement>;
      if (navigator.length > 0) {
        const firstSection = document.getElementById(sections[0].id);
        const firstSectionYCoordinates = getYCoordinates(firstSection);
        if (firstSectionYCoordinates && scroll > firstSectionYCoordinates - 150) {
          navigator[0].style.opacity = '100%';
        } else {
          navigator[0].style.opacity = '0%';
        }
      }
    });

    return (
      <>
        <div className="page_refero__navigator container">
          <button
            id="navigator-button"
            className="page_refero__navigatorheader"
            onClick={toggleNavigatorExpand}
            aria-expanded={isNavigatorMenuExpanded}
          >
            <div className="page_refero__navigatorheader-text">{sectionNameToBeDisplayed}</div>
            {isNavigatorMenuExpanded ? <Icon svgIcon={ChevronUp} /> : <Icon svgIcon={ChevronDown} />}
          </button>
          {isNavigatorMenuExpanded && renderNavigatorMenu()}
        </div>
      </>
    );
  }
};

export default Navigator;
