export const getSections = (): { id: string; name: string }[] => {
  const sectionNodes = document.querySelectorAll('.mol_form--content > section[id]');
  const sections: Array<{ id: string; name: string }> = [];
  sectionNodes.forEach(node => {
    if (node.id !== 'navigator_blindzone') {
      const sectionName = node.getAttribute('data-sectionname');
      if (sectionName && sectionName !== '') {
        sections.push({ id: node.id, name: sectionName });
      }
    }
  });
  return sections;
};
