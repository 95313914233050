export enum TopMessageVisibility {
  On = 1,
  Off = 2,
}

export enum ForbudBegrunnelser {
  ScopeRepresentasjonsforholdSegSelv = 1,
  ScopeRepresentasjonsforholdFullmakt = 2,
  SkjemaTilpassetKunInnbygger = 3,
  SkjemaTilpassetIkkeFullmakt = 4,
  SkjemaTilpassetIkkePaVegnAvBarnUnder12 = 5,
  SkjemaTilpassetIkkePaVegnAvBarnMellom12Og16 = 6,
  SkjemaTilpassetIkkePaVegnAvBarn = 7,
  SperretAdresse = 8,
  Default = 9,
  SkjemaTilpassetIkkeUngdom = 10,
}
