import React, { FunctionComponent } from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

interface Props {
  resources: HNSkjemaFrontend;
  erKopiLagret: boolean;
  onOkButtonClick: () => void;
}

const confirmationLightbox: FunctionComponent<Props> = ({ resources, erKopiLagret, onOkButtonClick }) => {
  return (
    <Modal
      title={resources.sendtConfirmation}
      description={erKopiLagret ? resources.sendtConfirmationText : resources.sendtConfirmationDiscretion}
      onSuccess={onOkButtonClick}
      noCloseButton
    />
  );
};

export default confirmationLightbox;
