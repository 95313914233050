import React from 'react';

import { ThunkDispatch } from 'redux-thunk';

import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { HNaddEventListener } from '@helsenorge/framework-utils/web-component/events';

import { lagreSkjema } from '../actions/formData';
import { SkjemaAction } from '../actions/index';
import { HnGlobalState } from '../reducers';

interface Props {
  dispatch: ThunkDispatch<HnGlobalState, void, SkjemaAction>;
  timeSkjemaLoadedForUser: number;
  automatiskLagering: boolean;
  kanMellomlagres: boolean;
}

const eventListener = ({ dispatch, timeSkjemaLoadedForUser, automatiskLagering, kanMellomlagres }: Props): React.JSX.Element => {
  const webcompheader = document.querySelector('hn-webcomp-header');
  HNaddEventListener(HeaderFooterEvents.soonautosignout, webcompheader, () => {
    if (kanMellomlagres) {
      dispatch(lagreSkjema(timeSkjemaLoadedForUser, automatiskLagering));
    }
  });

  return <></>;
};

export default eventListener;
