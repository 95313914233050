import * as React from 'react';

import { Metadata } from '../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../types/Resources';

import HighlightBox from '@helsenorge/designsystem-react/components/HighlightBox';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

import { replaceLinks } from './navigator/replaceLinks';
import { AuthenticationRequirement } from '../constants/authenticationRequirement';
import { TopMessageVisibility } from '../constants/topMessageVisibility';
import {
  brukerHarIkkeTilgangOgKanPrinteSkjema,
  brukerHarIkkeTilgangTilSkjema,
  hentSotHarIkkeTilgangFeilmelding,
  vedleggMaFjernes,
  kanIkkeAutofylleskjema,
} from '../util';
import { hentOversettelse } from '../util/stringToLanguageLocales';

interface TopMessageProps {
  resources: HNSkjemaFrontend;
  authenticationRequirement?: AuthenticationRequirement;
  userMetadata: Metadata;
  printReference?: string;
  openSidebar: () => void;
  visibility: number | undefined;
}

const TopMessage = ({
  resources,
  authenticationRequirement,
  userMetadata,
  printReference,
  openSidebar,
  visibility,
}: TopMessageProps): JSX.Element | null => {
  let message: string | JSX.Element = '';

  function hentInformasjonAvHvordanSkjemaFyllesut(): JSX.Element | null {
    if (visibility === TopMessageVisibility.Off) return null;
    if (isAuthorized()) {
      return (userMetadata.CanOnlyBePerformedBySubject && !userMetadata.IsRepresentert) ||
        (!userMetadata.CanOnlyBePerformedBySubject && userMetadata.IsRepresentert) ? (
        <HighlightBox size="fluid" color="blueberry">
          {replaceLinks(resources.message_canNotRepresentOthers, resources, openSidebar)}
        </HighlightBox>
      ) : (
        <HighlightBox size="fluid" color="blueberry">
          {replaceLinks(resources.message_canRepresentOthers, resources, openSidebar)}
        </HighlightBox>
      );
    }
    return null;
  }

  function hentNotificationPanelMelding(): JSX.Element | null {
    if (!isAuthorized()) {
      if (authenticationRequirement === AuthenticationRequirement.Anonymous) {
        return null;
      }
      if (authenticationRequirement === AuthenticationRequirement.Optional) {
        message = resources.skjemaLoginMessageAuthenticationRequirementOptionalPrint;
      } else if (printReference) {
        if (visibility === TopMessageVisibility.Off) return null;
        message = resources.skjemaLoginMessageAuthenticationRequirementRequiredPrint;
      } else {
        message = resources.skjemaLoginMessageAuthenticationRequirementRequiredNoPrint;
      }
      return <NotificationPanel variant="info">{replaceLinks(message, resources, openSidebar)}</NotificationPanel>;
    }
    if (brukerHarIkkeTilgangOgKanPrinteSkjema(userMetadata, printReference)) {
      if (visibility === TopMessageVisibility.Off) return null;
      return (
        <NotificationPanel variant="info">
          {replaceLinks(
            `${hentSotHarIkkeTilgangFeilmelding(userMetadata.ForbudBegrunnelse, resources)} ${resources.message_printVersionAvailable}`,
            resources,
            openSidebar
          )}
        </NotificationPanel>
      );
    }
    if (brukerHarIkkeTilgangTilSkjema(userMetadata, printReference)) {
      return (
        <NotificationPanel variant="alert">
          {replaceLinks(hentSotHarIkkeTilgangFeilmelding(userMetadata.ForbudBegrunnelse, resources), resources, openSidebar)}
        </NotificationPanel>
      );
    }
    if (vedleggMaFjernes(userMetadata)) {
      return <NotificationPanel variant="warn">{resources.message_skjemaHaddeVedlegg}</NotificationPanel>;
    }
    if (kanIkkeAutofylleskjema(userMetadata)) {
      return <NotificationPanel variant="warn">{hentOversettelse('kan_ikke_autofylle_skjema', resources)}</NotificationPanel>;
    }
    return null;
  }

  return (
    <>
      {hentNotificationPanelMelding()}
      {hentInformasjonAvHvordanSkjemaFyllesut()}
    </>
  );
};

export default TopMessage;
