(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dompurify"), require("react"), require("react-dom"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["dompurify", "react", "react-dom", "moment"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("dompurify"), require("react"), require("react-dom"), require("moment")) : factory(root["DOMPurify"], root["React"], root["ReactDOM"], root["moment"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE__65166__, __WEBPACK_EXTERNAL_MODULE__15442__, __WEBPACK_EXTERNAL_MODULE__56003__, __WEBPACK_EXTERNAL_MODULE__86798__) => {
return 