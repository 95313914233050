import moment from 'moment';

import { error } from '@helsenorge/framework-utils/logger';

const ResponseCorrolationId_Log_Label = 'Response corrolation id:';
const Feilkode_Log_Label = 'Feilkode: ';
const httpStatusCode_Log_Label = ' HttpStatusCode:';
const tidFraApnetSkjema_Log_Label = 'Tid fra apnet skjema:';
export const FULLFORING_AV_SKJEMA_FEIL = 'Fullføring av skjema feilet';
export const LAGRING_AV_SKJEMA_FEIL = 'Lagring av skjema feilet';
export const TID_KUNNE_IKKE_BEREGNES = '?';
export const WHITE_SPACE = ' ';

export const logSkjemaLagringsFeil = (
  feilmelding: string,
  responseCorrolationId: string | null,
  feilkode: string,
  httpStatusCode: number,
  tidFraApnetSkjema: number
): void => {
  let tidBrukt: string | undefined = undefined;
  if (tidFraApnetSkjema !== undefined) {
    const endTime = moment();
    const diff = moment.duration(endTime.diff(tidFraApnetSkjema));
    if (diff !== undefined) {
      tidBrukt = moment.utc(diff.asMilliseconds()).format('HH:mm:ss');
    }
  } else {
    tidBrukt = TID_KUNNE_IKKE_BEREGNES;
  }

  error(
    "'" +
      feilmelding +
      "'" +
      WHITE_SPACE +
      ResponseCorrolationId_Log_Label +
      WHITE_SPACE +
      responseCorrolationId +
      WHITE_SPACE +
      Feilkode_Log_Label +
      WHITE_SPACE +
      feilkode +
      WHITE_SPACE +
      httpStatusCode_Log_Label +
      WHITE_SPACE +
      httpStatusCode +
      WHITE_SPACE +
      tidFraApnetSkjema_Log_Label +
      WHITE_SPACE +
      tidBrukt
  );
};
