import React, { FunctionComponent } from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

interface Props {
  resources: HNSkjemaFrontend;
  hideCancelLightbox: () => void;
  onAbortSkjema: () => void;
}

const cancelLightbox: FunctionComponent<Props> = ({ resources, hideCancelLightbox, onAbortSkjema }) => {
  return (
    <Modal
      title={resources.cancelLightboxHeader}
      description={resources.cancelLightboxText}
      onSuccess={onAbortSkjema}
      primaryButtonText={resources.avsluttSkjema}
      onClose={hideCancelLightbox}
      secondaryButtonText={resources.fortsett}
    />
  );
};

export default cancelLightbox;
