import { pushToDataLayer, CustomData } from '@helsenorge/framework-utils/adobe-analytics';

interface FormEvent extends CustomData {
  event: string;
  form: { name?: string };
}

export const trackFormEvent = (event: string, formName: string): void => {
  let formEvent: FormEvent;

  switch (event) {
    case 'form start':
      formEvent = {
        event: 'form start',
        form: { name: formName },
      };
      break;
    case 'form complete':
      formEvent = {
        event: 'form complete',
        form: { name: formName },
      };
      break;
    case 'form save':
      formEvent = {
        event: 'form save',
        form: { name: formName },
      };
      break;
    case 'form continue':
      formEvent = {
        event: 'form continue',
        form: { name: formName },
      };
      break;
    case 'site error':
      pushToDataLayer({
        event: 'site error',
        error: {
          level: 4,
          details: 'noen av feltene er ikke fylt ut riktig',
        },
      });
      return;
    default:
      formEvent = {
        event: '',
        form: {},
      };
      break;
  }

  if (formEvent.event !== '') {
    pushToDataLayer(formEvent);
  } else {
    return;
  }
};
