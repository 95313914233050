import { Questionnaire } from 'fhir/r4';

import { getLanguageDisplay } from './getLanguageDisplay';
type LanguagesDefinition = { Language: string | ''; DefinitionUrl: string | ''; Display: string | '' };
export const getLanguagesInDefinition = (questionnaires: Questionnaire[]): LanguagesDefinition[] => {
  return questionnaires?.map(q => {
    return {
      Language: q.language || '',
      DefinitionUrl: q.url || '',
      Display: getLanguageDisplay(q) || '',
    };
  });
};
