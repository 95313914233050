import { ValueSet, QuestionnaireItem, QuestionnaireResponse, Questionnaire } from 'fhir/r4';
import { ThunkDispatch } from 'redux-thunk';

import { UpdateSkjemasvarResponse, CreateSkjemasvarRequest, UpdateSkjemasvarRequest } from '../types/MinHelseEntities';

import { get, post, put, HelsenorgeProxyError } from '@helsenorge/framework-utils/hn-proxy-service';
import { setSkjemaDefinition } from '@helsenorge/refero/actions/form';
import { ProcedureStatusCodes } from '@helsenorge/refero/types/fhirEnums';
import { OrgenhetHierarki } from '@helsenorge/refero/types/orgenhetHierarki';

import { GlobalState } from '../root/reducer';
import { getQueryParams, oppdaterQuestionnaireResponseSomNyReponseHvisGjenbrukes, getFeilmelding } from '../util';
import { FULLFORING_AV_SKJEMA_FEIL, LAGRING_AV_SKJEMA_FEIL, logSkjemaLagringsFeil } from '../util/logUtil';
import { redirectEtterFullfortSkjema } from '../util/veileder';

import { SkjemaAction, shouldShowBeforeUnload, redirectTilLagretSkjemaPaaDokumenter } from './index';

export const SEND_INN_SKJEMA = 'skjemautfyller/SEND_INN_SKJEMA';
export const SEND_INN_SKJEMA_DONE = 'skjemautfyller/SEND_INN_SKJEMA_DONE';
export const SEND_INN_SKJEMA_FAILED = 'skjemautfyller/SEND_INN_SKJEMA_FAILED';
export const DISMISS_SEND_INN_SKJEMA_FAILED = 'skjemautfyller/DISMISS_SEND_INN_SKJEMA_FAILED';
export const DISMISS_LAGRE_SKJEMA_FAILED = 'skjemautfyller/DISMISS_LAGRE_SKJEMA_FAILED';
export const KAN_IKKE_LAGRE_ERROR = 'skjemautfyller/KAN_IKKE_LAGRE_ERROR';
export const DISMISS_KAN_IKKE_LAGRE_ERROR = 'skjemautfyller/DISMISS_KAN_IKKE_LAGRE_ERROR';
export const LAGRE_SKJEMA_DONE = 'skjemautfyller/LAGRE_SKJEMA_DONE';
export const LAGRE_SKJEMA = 'skjemautfyller/LAGRE_SKJEMA';
export const LAGRE_SKJEMA_FAILED = 'skjemautfyller/LAGRE_SKJEMA_FAILED';
export const ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED = 'skjemautfyller/ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED';
export const HIDE_ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED = 'skjemautfyller/HIDE_ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED';
export const LOAD_VALUESET = 'skjemautfyller/LOAD_VALUESET';

export const startSendInnSkjema = (): SkjemaAction => {
  return {
    type: SEND_INN_SKJEMA,
  };
};

export const sendInnEllerLagreSkjemaFailed = (error: HelsenorgeProxyError, type: string): SkjemaAction => {
  return {
    type: type,
    error: getFeilmelding(error),
  };
};

export const sendInnSkjemaDone = (samtaleGuid?: string): SkjemaAction => {
  if (samtaleGuid) {
    return {
      type: SEND_INN_SKJEMA_DONE,
      samtaleGuid,
    };
  } else {
    return {
      type: SEND_INN_SKJEMA_DONE,
    };
  }
};

export const startLagreSkjema = (): SkjemaAction => {
  return {
    type: LAGRE_SKJEMA,
  };
};

export const lagreSkjemaDone = (dokumentGuid: string): SkjemaAction => {
  return {
    type: LAGRE_SKJEMA_DONE,
    dokumentGuid,
  };
};

export const dismissSendInnError = (): SkjemaAction => {
  return {
    type: DISMISS_SEND_INN_SKJEMA_FAILED,
  };
};

export const dismissLagreError = (): SkjemaAction => {
  return {
    type: DISMISS_LAGRE_SKJEMA_FAILED,
  };
};

export const kanIkkeLagreError = (): SkjemaAction => {
  return {
    type: KAN_IKKE_LAGRE_ERROR,
  };
};

export const dismissKanIkkeLagre = (): SkjemaAction => {
  return {
    type: DISMISS_KAN_IKKE_LAGRE_ERROR,
  };
};

export const anonymousUploadOfAttachmentDenied = (): SkjemaAction => {
  return {
    type: ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED,
  };
};

export const hideAnonymousUploadOfAttachmentDenied = (): SkjemaAction => {
  return {
    type: HIDE_ANONYMOUS_UPLOAD_OF_ATTACHMENT_DENIED,
  };
};

const createQuestionnaireResponse = (state: GlobalState): QuestionnaireResponse => {
  return {
    ...state.refero.form.FormData.Content,
    questionnaire: state.refero.form.FormDefinition.Content?.url,
  } as QuestionnaireResponse;
};

const createSavePayload = (
  qr: string,
  state: GlobalState,
  automatiskLagering: boolean,
  withDiscretion?: boolean
): UpdateSkjemasvarRequest | CreateSkjemasvarRequest => {
  const dokumentGuid = state.refero.ui.dokumentGuid;
  const taskId = state.refero.ui.taskId;

  return {
    ...(dokumentGuid && { DokumentGuid: dokumentGuid }),
    ...(taskId && { OppgaveGuid: taskId }),
    ...(withDiscretion && { Discretion: true }),
    ...{ AutomatiskLagering: automatiskLagering },
    QuestionnaireResponse: qr,
  };
};

const isNewDocument = (state: GlobalState): boolean => {
  return state.refero.ui.userMetadata?.IsNewDocument ?? false;
};

export const sendInnSkjema = (
  timeSkjemaLoadedForUser: number,
  automatiskLagering: boolean,
  withDiscretion?: boolean,
  onCompleteMicroweb?: (formData: Record<string, string | undefined>) => void
) => {
  return (dispatch: ThunkDispatch<GlobalState, void, SkjemaAction>, getState: () => GlobalState): void => {
    const isNewDoc = isNewDocument(getState());
    dispatch(startSendInnSkjema());

    const qr = JSON.stringify({
      ...createQuestionnaireResponse(getState()),
      status: ProcedureStatusCodes.COMPLETED,
    });
    const payload = createSavePayload(qr, getState(), automatiskLagering, withDiscretion);
    const sendFn = Object.prototype.hasOwnProperty.call(payload, 'DokumentGuid') && !isNewDoc ? put : post;

    sendFn<UpdateSkjemasvarResponse, CreateSkjemasvarRequest | UpdateSkjemasvarRequest>('skjemainternal', 'api/v1/Skjemasvar', payload)
      .then((response: UpdateSkjemasvarResponse) => {
        dispatch(shouldShowBeforeUnload(false));

        if (response.SamtaleGuid && !isNewDoc) {
          dispatch(sendInnSkjemaDone(response.SamtaleGuid));
        } else {
          // redirect til dokumenter eller til neste steg i veileder
          dispatch(sendInnSkjemaDone());
          const queryParams = getQueryParams();
          redirectEtterFullfortSkjema(
            getState().refero.form.FormDefinition,
            getState().refero.form.FormData,
            queryParams.redirect,
            queryParams.contextParameter,
            response.DokumentGuid,
            () => redirectTilLagretSkjemaPaaDokumenter(response.DokumentGuid),
            onCompleteMicroweb
          );
        }
      })
      .catch((error: HelsenorgeProxyError) => {
        dispatch(sendInnEllerLagreSkjemaFailed(error, SEND_INN_SKJEMA_FAILED));
        // Logger etter dispatch in case en gang logger metoden feiler så får
        // fortsatt bruker en feilmelding
        logSkjemaLagringsFeil(
          FULLFORING_AV_SKJEMA_FEIL,
          error.correlationId,
          error.response?.code,
          error.statusCode,
          timeSkjemaLoadedForUser
        );
      });
  };
};

export const overstyrSkjemaFraMicroweb = (
  currentQuestionnaire: Questionnaire,
  questionnaireResponse: QuestionnaireResponse | undefined
) => {
  return (dispatch: ThunkDispatch<GlobalState, void, SkjemaAction>): void => {
    dispatch(setSkjemaDefinition(currentQuestionnaire, questionnaireResponse, undefined, true));
  };
};

export const lagreSkjema = (timeSkjemaLoadedForUser: number, automatiskLagering: boolean) => {
  return (dispatch: ThunkDispatch<GlobalState, void, SkjemaAction>, getState: () => GlobalState): void => {
    dispatch(startLagreSkjema());
    const questionnaireResponse = createQuestionnaireResponse(getState());
    const globalState = getState();
    oppdaterQuestionnaireResponseSomNyReponseHvisGjenbrukes(questionnaireResponse, globalState.refero.ui.userMetadata);
    const qr = JSON.stringify(questionnaireResponse);
    const payload = createSavePayload(qr, getState(), automatiskLagering);
    const lagreFn = Object.prototype.hasOwnProperty.call(payload, 'DokumentGuid') && !isNewDocument(globalState) ? put : post;

    lagreFn<UpdateSkjemasvarResponse, CreateSkjemasvarRequest | UpdateSkjemasvarRequest>('skjemainternal', 'api/v1/Skjemasvar', payload)
      .then((response: UpdateSkjemasvarResponse) => {
        // set questionnaire & questionnaireResponse in npm store
        const questionnaireResponse: QuestionnaireResponse = JSON.parse(response.QuestionnaireResponse);
        const currentQuestionnaire =
          getState().refero.ui.questionnaires.filter(x => `Questionnaire/${x.id}` === questionnaireResponse.questionnaire)[0] ||
          getState().refero.ui.questionnaires[0];
        dispatch(setSkjemaDefinition(currentQuestionnaire, questionnaireResponse, undefined, true));
        dispatch(lagreSkjemaDone(response.DokumentGuid));
      })
      .catch((error: HelsenorgeProxyError) => {
        dispatch(sendInnEllerLagreSkjemaFailed(error, LAGRE_SKJEMA_FAILED));
        // Logger etter dispatch in case en gang logger metoden feiler så får
        // fortsatt bruker en feilmelding
        logSkjemaLagringsFeil(LAGRING_AV_SKJEMA_FEIL, error.correlationId, error.response?.code, error.statusCode, timeSkjemaLoadedForUser);
      });
  };
};

export const loadValueSet = (
  searchString: string,
  item: QuestionnaireItem,
  successCallback: (valueSet: ValueSet) => void,
  errorCallback: (error: string) => void
): void => {
  get('skjemainternal', 'api/v1/ValueSet/$expand', {
    url: encodeURIComponent(item.answerValueSet || ''),
    filter: encodeURIComponent(searchString),
  })
    .then((response: { ValueSet: Array<{ System: string; Code: string; Display: string }> }) => {
      const valueSet: ValueSet = {
        resourceType: 'ValueSet' as const,
        status: 'draft',
        compose: {
          include: [
            {
              system: response.ValueSet.length > 0 ? response.ValueSet[0].System : '',
              concept: response.ValueSet.map(x => {
                return {
                  code: x.Code,
                  display: x.Display,
                };
              }),
            },
          ],
        },
      };
      successCallback(valueSet);
    })
    .catch((error: string) => {
      errorCallback(error);
    });
};

export const lastMottakere = (
  skjemaTekniskNavn: string,
  successCallback: (receivers: Array<OrgenhetHierarki>) => void,
  errorCallback: () => void
): void => {
  get('skjemainternal', `api/v1/OrgEnhetHierarki?skjemaNavn=${skjemaTekniskNavn}`)
    .then((response: { Orgenhet: Array<OrgenhetHierarki> }) => {
      successCallback(response.Orgenhet);
    })
    .catch(() => {
      errorCallback();
    });
};
