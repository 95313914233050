import React, { FunctionComponent } from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

interface Props {
  resources: HNSkjemaFrontend;
  closeCallback: () => void;
  sendCallback: (withDiscretion?: boolean) => void;
}

const DiscretionLightbox: FunctionComponent<Props> = ({ resources, sendCallback }) => {
  return (
    <Modal
      title={resources.confirmDiscretionTitle}
      description={resources.confirmDiscretionText}
      primaryButtonText={resources.confirmDiscretionlagreAndSendButtonText}
      secondaryButtonText={resources.confirmDiscretionSendButtonText}
      onSuccess={(): void => sendCallback()}
      onClose={(): void => sendCallback(true)}
    />
  );
};

export default DiscretionLightbox;
